import pdf from '../../pdf/Appbay - Case Study For Banking Domain.pdf'
import Banking1 from 'images/Banking.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Banking = {

    heading: "Banking",
    subheading: "",
    description:  <>
    <strong>Client : </strong>Banking customer<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Large number of manual processes resulted error-prone and lesser operational efficiency. </p>
    <p><ChevronRightIcon/> Streamline banking operations and get easy and faster way of handling large volumes of data.  </p>
    <p><ChevronRightIcon/> Core banking processes were still driven largely by pen and paper. </p>
    <br/>
    <strong>Solution : </strong> Appbay helped to create an Appian-driven solution that's made it easy to transition from a manual, cumbersome, and paper-driven operation. With the new system in place, they've streamlined work, increased transparency, and brought everything together under one common platform.
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> 4x reduction in manual effort. Through bulk uploads, Repetitive tasks that were previously manual have been streamlined. </p>
    <p><ChevronRightIcon/> Improved productivity and significant reduction in human - errors </p>
    <p><ChevronRightIcon/> Improved end-user experience through easy access of data using records and reports </p>
    </>,
    imgSrc: Banking1,
    pdf: pdf

}
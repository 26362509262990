import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link } from "react-router-dom";
import "./light.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Header = tw.header`
  flex justify-between items-center
   mx-auto 
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 className on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-5 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hocus:text-primary-500
  text-gray-100
`;

export const NavText = tw.p`
  text-lg my-2 lg:text-sm lg:my-0
  font-semibold 
  text-gray-900 flex flex-row text-left 
  hover:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  
  px-3 py-2 mx-auto rounded bg-primary-500 text-gray-900
  hocus:bg-primary-700 hocus:text-gray-700 focus:shadow-outline w-1/2
  border-b-0 
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! mx-5`};

  img {
    ${tw`w-1/2 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none  transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col text-center mt-2`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center py-3 px-10
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState([false, false, false]);
  const [action, SetAction] = useState(false);
  const Mobile = window.innerWidth < 768;

  const handleClick = (event) => {
    const link = event.target.name;
    setAnchorEl(event.currentTarget);
    const temp = [false, false, false];
    temp[link] = true;
    setOpen(temp);
    console.log(temp);
  };

  const handleRequestClose = () => {
    setOpen([false, false, false]);
  };

  const handleAction = () => {
    SetAction(true);
  };

  const handleClickAway = () => {
    if (!Mobile) {
      handleRequestClose();
    }
  };

  const defaultLinks = [
    <>
      <NavLinks key={1} style={{ marginLeft: "auto" }}>
        <div className="dropdown">
          <NavLink rel="nofollow" className="dropbtn" href="#">
            Services <ArrowDropDownIcon style={{ verticalAlign: "top" }} />
          </NavLink>
          <div className="dropdown-content">
            <div className="dropdown">
              <MenuItem className="dropbtn">
                <NavText>Services by capabilities</NavText>
                <ArrowDropDownIcon style={{ marginLeft: "28px" }} />
              </MenuItem>
              <div className="dropdown-content">
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/bpm"
                >
                  <NavText>BPM</NavText>
                </MenuItem>
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/crm"
                >
                  <NavText>CRM</NavText>
                </MenuItem>
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  onClick={() => {
                    window.open("https://training.appbaytech.com");
                  }}
                >
                  <NavText>Training</NavText>
                </MenuItem>
              </div>
            </div>
            <div className="dropdown">
              <MenuItem className="dropbtn">
                <NavText>Services by business needs</NavText>
                <ArrowDropDownIcon tw="text-right" />
              </MenuItem>
              <div className="dropdown-content">
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/Consultation"
                >
                  <NavText>Consultation/Advisory</NavText>
                </MenuItem>
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/Development"
                >
                  <NavText>Development, Implementation and Support</NavText>
                </MenuItem>
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/Quality"
                >
                  <NavText>Quality Assurance</NavText>
                </MenuItem>
                <MenuItem
                  onMouseOver={handleAction}
                  onClick={handleRequestClose}
                  component={Link}
                  to="/services/Excellence"
                >
                  <NavText>Center of Excellence</NavText>
                </MenuItem>
              </div>
            </div>
          </div>
        </div>

        <div className="dropdown">
          <NavLink className="dropbtn" rel="nofollow" href="#">
            Industries <ArrowDropDownIcon style={{ verticalAlign: "top" }} />
          </NavLink>
          <div className="dropdown-content">
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/Insurance"
            >
              <NavText>Insurance</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/Governance"
            >
              <NavText>Governance</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/Telecom"
            >
              <NavText>Telecom</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/HealthCare"
            >
              <NavText>Health care</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/Translation"
            >
              <NavText>Translation</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/industries/Banking"
            >
              <NavText>Banking</NavText>
            </MenuItem>
          </div>
        </div>

        <div className="dropdown">
          <NavLink className="dropbtn" rel="nofollow" href="#">
            About-us <ArrowDropDownIcon style={{ verticalAlign: "top" }} />
          </NavLink>
          <div className="dropdown-content">
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/about-us"
            >
              <NavText>Our philosphy</NavText>
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/about-us"
            >
              <NavText>Need help?</NavText>
            </MenuItem>
            {/* <MenuItem
              onMouseOver={handleAction}
              onClick={handleRequestClose}
              component={Link}
              to="/career"
            >
              <NavText>Careers</NavText>
            </MenuItem> */}
          </div>
        </div>
      </NavLinks>
      <NavLinks key={2}>
        <PrimaryLink href="/contact-us/connect">Contact us</PrimaryLink>
      </NavLinks>
    </>,
  ];

  const mobileLinks = [
    <>
      <NavLinks key={1}>
        <NavLink
          aria-owns={open[0] ? "services-menu" : null}
          aria-haspopup="true"
          onClick={handleClick}
          name={0}
          tw="text-black"
          rel="nofollow"
          href="#"
        >
          Services <ArrowDropDownIcon />
        </NavLink>
        {open[0] ? (
          <div
            id="services-menu"
            style={{ backgroundColor: "#F9F9F9", border: "3px solid #243E63" }}
          >
            {/* <Divider /> */}
            <MenuItem value="" style={{ backgroundColor: "#F9F9F9" }}>
              <NavText>Services by capabilities</NavText>
            </MenuItem>
            {/* <Divider /> */}
            <MenuItem
              onMouseOver={handleAction}
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/bpm"
            >
              BPM
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/crm"
            >
              CRM
            </MenuItem>
            <MenuItem
              onMouseOver={handleAction}
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              onClick={() => {
                window.open("https://training.appbaytech.com");
              }}
            >
              Training
            </MenuItem>
            {/* <Divider /> */}
            <MenuItem value="" style={{ backgroundColor: "#F9F9F9" }}>
              <NavText>Services by business needs</NavText>
            </MenuItem>
            {/* <Divider /> */}
            <MenuItem
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/Consultation"
            >
              Consultation/Advisory
            </MenuItem>
            <MenuItem
              style={{
                marginLeft: 15,
                fontFamily: "montserrat",
                textAlign: "left",
              }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/Development"
            >
              Development, Implementation and <br />
              Support
            </MenuItem>
            <MenuItem
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/Quality"
            >
              Quality Assurance
            </MenuItem>
            <MenuItem
              style={{ marginLeft: 15, fontFamily: "montserrat" }}
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/services/Excellence"
            >
              Center of Excellence
            </MenuItem>
          </div>
        ) : null}
        <NavLink
          aria-owns={open[1] ? "industries-menu" : null}
          aria-haspopup="true"
          onClick={handleClick}
          name={1}
          tw="text-black"
          rel="nofollow"
          href="#"
        >
          Industries <ArrowDropDownIcon />
        </NavLink>
        {open[1] ? (
          <div
            id="industries-menu"
            style={{ backgroundColor: "#F9F9F9", border: "3px solid #243E63" }}
          >
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/Insurance"
            >
              <NavText>Insurance</NavText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/Governance"
            >
              <NavText>Governance</NavText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/Telecom"
            >
              <NavText>Telecom</NavText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/HealthCare"
            >
              <NavText>Health care</NavText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/Translation"
            >
              <NavText>Translation</NavText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRequestClose();
                toggleNavbar();
              }}
              component={Link}
              to="/industries/Banking"
            >
              <NavText>Banking</NavText>
            </MenuItem>
          </div>
        ) : null}
        <NavLink
          aria-owns={open[2] ? "about-us-menu" : null}
          aria-haspopup="true"
          onClick={handleClick}
          name={2}
          tw="text-black"
          rel="nofollow"
          href="#"
        >
          About-us <ArrowDropDownIcon />
        </NavLink>
      </NavLinks>
      {open[2] ? (
        <div
          id="about-us-menu"
          style={{ backgroundColor: "#F9F9F9", border: "3px solid #243E63" }}
        >
          <MenuItem
            onClick={() => {
              handleRequestClose();
              toggleNavbar();
            }}
            component={Link}
            to="/about-us"
          >
            <NavText>Our philosphy</NavText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleRequestClose();
              toggleNavbar();
            }}
            component={Link}
            to="/about-us"
          >
            <NavText>Need help?</NavText>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              handleRequestClose();
              toggleNavbar();
            }}
            component={Link}
            to="/career"
          >
            <NavText>Careers</NavText>
          </MenuItem> */}
        </div>
      ) : null}
      <NavLinks key={2}>
        <PrimaryLink href="/contact-us/connect">Contact us</PrimaryLink>
      </NavLinks>
    </>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" width={"300px"} height={"500px"} />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header
      className={className || "header-light"}
      onScroll={handleRequestClose}
      style={{ zIndex: 900000 }}
    >
      <DesktopNavLinks
        css={collapseBreakpointCss.desktopNavLinks}
        style={{
          backgroundColor: "#1A202C",
        }}
      >
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
        style={{
          backgroundColor: "#1A202C",
        }}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {mobileLinks}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6 mr-10 mt-5" />
          ) : (
            <MenuIcon tw="w-6 h-6 mr-10 text-primary-500" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

import * as React from 'react';

import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import tw from "twin.macro";
import JotFormReact from 'jotform-react';
import Button from '@mui/material/Button';
import {
  Link, useHistory
} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { SectionHeading } from "components/misc/Headings";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CancelIcon from '@mui/icons-material/Cancel';

function Hidden() {
  return (
    <div style={{
      backgroundColor: 'white',
      height: 50,
      width: window.innerWidth,
      bottom: 50,
      position: 'relative'
    }}></div>
  )
}

const Heading = tw(SectionHeading)`text-xl`;

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [value, setValue] = React.useState(0);
  let history = useHistory()

  const handleClickOpen = () => {
    props.form();
  };
  const whatsappOpen = () => {
    window.open("https://api.whatsapp.com/send?phone=919660339761&text=Hi,%20I%20would%20like%20to%20ask%20about")
  };

  const handleClose = () => {
    props.form()
  };

  const handleSubmit = () => {
    props.form()
  };

  return (
    <div>
      {
        window.innerWidth < 768
          ?
          <Box sx={{ width: window.innerWidth, position: 'fixed', bottom: 0,zIndex:1000 }}>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <Button variant="contained" tw="hover:bg-primary-500" onClick={whatsappOpen} sx={{ backgroundColor: '#4BCA5A', padding: 2, borderRadius: '50%',right: window.innerWidth/3,  }}>
                <WhatsAppIcon />
              </Button>
              <Button variant="contained" tw="hover:bg-primary-500" onClick={handleClickOpen} sx={{ backgroundColor: '#F6C453', padding: 2, borderRadius: '50%',left: window.innerWidth/3 }}>
                <ContactPageIcon />
              </Button>
            </BottomNavigation>
          </Box>
          :
          <Box sx={{ width: window.innerWidth, position: 'fixed', bottom: 0,zIndex:1000 }}>
            <Button variant="contained" tw="hover:bg-primary-500" onClick={handleClickOpen} sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#F6C453', padding: 2, borderRadius: '50%' }}>
              <ContactPageIcon />
            </Button>
            <Button variant="contained" onClick={whatsappOpen} sx={{ position: 'fixed', bottom: 16, left: 16, backgroundColor: '#4BCA5A', padding: 2, borderRadius: '50%' }}>
              <WhatsAppIcon />
            </Button>
          </Box>
      }
      <Dialog open={props.open} onClose={handleClose}>
        {/* <DialogTitle>Subscribe</DialogTitle> */}
        <CancelIcon tw="ml-auto text-primary-500 my-2 mr-2 text-4xl"
        style={{
          cursor: 'pointer',
          fontSize:30
        }}
        onClick={handleClose}/>
        <DialogContent>
          <Heading>Enquiry <span tw="text-primary-500 font-black">Form</span></Heading>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <JotFormReact
            formURL="https://form.jotform.com/220340771756455"
            onSubmit={handleSubmit}
            initialHeight={300} // Form will opens in this size
            autoResize={true} // should form auto-resize
            tw="mt-5"
          />
          <Hidden />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
          
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

import React from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import Healthcare from "images/Healthcare.svg";
import Banking from "images/Banking.svg";
import Insurance from "images/Insurance.svg";
import Governance from "images/Governance.svg";
import Telecom from "images/Telecom.svg";
import Translation from "images/Translation.jpg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const Container = tw.div``;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-10 md:py-20  items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 items-center justify-center`;
const ImageColumn = tw(Column)`relative lg:w-1/2 lg:h-96 h-40 justify-center items-center flex flex-row ml-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");
  background-repeat: no-repeat;
  
  
  `,
  
  tw`rounded bg-contain h-full w-full flex justify-center lg:ml-0 ml-10`,
]);
const TextContent = tw.div`lg:py-8 items-center`;

const Heading = tw(SectionHeading)`w-full lg:pt-4 pt-8`;
const DescriptionHeading = tw.h1`my-5 font-bold text-3xl lg:text-left text-center leading-none text-primary-500`;
const Description = tw.p`text-sm font-medium text-justify text-secondary-100 mt-4 px-5 lg:px-0`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-primary-500`
const Key = tw.div`font-medium text-gray-700`
const PrimaryButton = tw(PrimaryButtonBase)`my-10 px-10 text-sm inline-block mx-auto items-center`;

const formatText = (text) => {
  return (
    <div tw="flex flex-row justify-start">
      <span tw="text-primary-500">
        <ChevronRightIcon/>
      </span>
      <span tw="ml-1">
        {text}
      </span>
    </div>
  )
}

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const textContent = [
    {
      title: "Banking",
      image: Banking,
      description:  <>
      <strong>Client : </strong>Banking customer<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Large number of manual processes resulted error-prone and lesser operational efficiency. </p>
      <p><ChevronRightIcon/> Streamline banking operations and get easy and faster way of handling large volumes of data.  </p>
      <p><ChevronRightIcon/> Core banking processes were still driven largely by pen and paper. </p>
      <br/>
      <strong>Solution : </strong> Appbay helped to create an Appian-driven solution that's made it easy to transition from a manual, cumbersome, and paper-driven operation. With the new system in place, they've streamlined work, increased transparency, and brought everything together under one common platform.
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> 4x reduction in manual effort. Through bulk uploads, Repetitive tasks that were previously manual have been streamlined. </p>
      <p><ChevronRightIcon/> Improved productivity and significant reduction in human - errors </p>
      <p><ChevronRightIcon/> Improved end-user experience through easy access of data using records and reports </p>
      </>,
      link:"/industries/Banking"
    },
    {
      title: "Insurance", 
      image: Insurance,
      description: <>
      <strong>Client : </strong>Insurance customer<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Less Operational efficiency. </p>
      <p><ChevronRightIcon/> The architecture including COTS and SaaS products, on-premises legacy systems, and multiple Cloud environments resulting into huge IT investment and lot of complexity. There was no centric orchestration layer that could connect and show 360 view of all systems on single screen.  </p>
      <p><ChevronRightIcon/> Very less agility </p>
      <br/>
      <strong>Solution : </strong> Created multiple apps that not only improved operational efficiency, increased sales enablement to higher level but also helped in innovating other aspects of the company to help them support in COVID era. Silos of excels were replaced by full suite of office solutions created to help both their customers and reps locate COVID-testing sites and information.
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> Gain 4x Agility Boost </p>
      <p><ChevronRightIcon/> Improved end-user experience and productivity </p>
      <p><ChevronRightIcon/> Centric tracker view to enable operational manager to take decisions based on the collaborative relational indicators. </p>
      </>,
      link:"/industries/Insurance"
    },
    {
      title: "Governance",
      image: Governance,
      description: <>
      <strong>Client : </strong>Governance<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Silos of data in multiple excels. </p>
      <p><ChevronRightIcon/> Needed business processes to get automated to reduce the approval time </p>
      <p><ChevronRightIcon/> Needed 360 view of all the subsidiaries helping to monitor status of all the business processes </p>
      <br/>
      <strong>Solution : </strong> Application enabled creating and editing for all the business approval cycles with transparency. It also contained 360 view that provided centric view for organizational head to see the seamlessly integrated data from legacy systems and relevant resources.
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> Centralized tracking, simplify creating and management of all the business proposals. </p>
      <p><ChevronRightIcon/> Eliminated error and business process approval delay </p>
      </>,
      link:"/industries/Governance"
    },
    {
      title: "Telecom",
      image: Telecom,
      description: <>
      <strong>Client : </strong>Telecom customer<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Complex architecture and un-synced legacy systems to handle data for millions of end-users, thereby resulting into huge number of man-hours to handle end-user basic operations. </p>
      <p><ChevronRightIcon/> Compromised PPI data of customers. </p>
      <p><ChevronRightIcon/> Poor operational efficiency resulting into poor end-user experience. </p>
      <br/>
      <strong>Solution : </strong>Appbay helped to create a Salesforce-driven solution that's made it easy to create Account and contacts without duplicity using salesforce out of the box features like exact and fuzzy matching rules. Further with help of permission sets and profiles, sensitive data was restricted for concerned customers. Using Salesforce inbuilt cache mechanism fast retrieval of customer data was achieved which earlier took huge amount of time
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> 5x reduction in manual effort due to reduced end-user complaints. </p>
      <p><ChevronRightIcon/> PPI issues eliminated </p>
      <p><ChevronRightIcon/> End to end automated business processes for such huge volume of data helped to grow high agility and productivity eliminating earlier silos and pen - paper processes. </p>
      </>,
      link:"/industries/Telecom"
    },
    {
      title: "HealthCare",
      image: Healthcare,
      description: <>
      <strong>Client : </strong>Health care Customer<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Client had multiple practices and at multiple location and hence needed support to manage the needful at all levels: strategic, tactical and operational. </p>
      <p><ChevronRightIcon/> Silos of data in multiple excels. </p>
      <p><ChevronRightIcon/> No centre point of view for all the legacy systems. </p>
      <p><ChevronRightIcon/> Too much manual intervention in every business process making it error prone. </p>
      <br/>
      <strong>Solution : </strong>Multi-tenant application (SaaS product) built on Appian that allows multiple customers/clients to get onboarded and use the EPD product application that not only manages medical data for patients, but allows you to plan appointments fully automatically, billing, the daily agenda for practitioners and reports to show how well the practice is doing, also has capabilities to configure and manage the treatment templates for different types of treatments dynamically.
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> Improving overall "Turn Around Time" of each process </p>
      <p><ChevronRightIcon/> Increased revenue for the client by 20% in the first year of product implementation </p>
      </>,
      link:"/industries/HealthCare"
    },
    {
      title: "Translation",
      image: Translation,
      description: <>
      <strong>Client : </strong>Translation customer<br/><br/>
      <strong>Challange : </strong><br/>
      <p><ChevronRightIcon/> Increasing and never-ending volumes of translation data and limited budget. </p>
      <p><ChevronRightIcon/> Reducing the time it takes to translate from English into local languages. </p>
      <p><ChevronRightIcon/> Translate high volumes of content, while simultaneously reducing the publishing time between English and local language websites. </p>
      <p><ChevronRightIcon/> Reduce Inconsistent experiences on local websites. </p>
      <br/>
      <strong>Solution : </strong> Application enabled the project management offices & the language offices of client to better manage & automate the translation & delivery process end-to-end.
      <br/><br/>
      <strong>ROI : </strong>
      <p><ChevronRightIcon/> Approximately 20,000 translation jobs are being created each month in application by the users who are based in different parts of world to translate 50 million + words from over 286 different language flavours </p>
      <p><ChevronRightIcon/> It has helped them manage complex business scenarios with ease and improve the end-user experience and productivity. </p>
      <p><ChevronRightIcon/> Reduces human errors </p>
      </>,
      link:"/industries/Translation"
    },

  ]

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
  };

  const replaceCommaLine=(data)=> {
    let dataToArray = data.split(',')
    dataToArray.map((line,i)=>{
      dataToArray[i] = <p>&#8226; {line}<br/></p>
    })
    return(dataToArray)

  }

  return (
    <Container>
      <Heading>Domain  <span tw="text-primary-500 font-black">Expertise</span></Heading>

      <Box sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '40px' }} tw="lg:px-10">
        <Tabs value={value} onChange={handleChange}  variant={window.innerWidth >= 1024 ?"fullWidth":"scrollable"} scrollButtons={true} centered TabIndicatorProps={{style: {background:'#F6C453'}}}>
          {
            textContent.map((t, i) => {
              return <Tab  label={t.title} style={{fontWeight:'bolder',color:'#1A202C'}} />
            })
          }
        </Tabs>
      </Box>
      
      <TwoColumn style={{alignItems:'center'}}>
        <ImageColumn>
          <Image imageSrc={textContent[value].image} style={{marginLeft: window.innerWidth<768?window.innerWidth/4:null}}/>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <DescriptionHeading>{textContent[value].title}</DescriptionHeading> */}
            <Description>{textContent[value].description}</Description>
            <PrimaryButton as="a" href={textContent[value].link}>
              Download Case Study
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ValueFeatures from "components/features/DashedBorderSixFeatures";
import SliderCard from "components/cards/ThreeColSlider.js";
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AttractionsIcon from '@mui/icons-material/Attractions';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AvTimerIcon from '@mui/icons-material/AvTimer';

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AnimationIcon from '@mui/icons-material/Animation';


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const cards = [
  {
    imageSrc: <SmartButtonIcon/>,
    title: " Have fun with what you do, and enjoy the challenges",
  },
  {
    imageSrc: <AutoAwesomeIcon/>,
    title: "Being Ethical",
  },
  {
    imageSrc: <AttractionsIcon/>,
    title: "We take 100% responsibility for our actions & look for solutions",
  },
  {
    imageSrc: <LightbulbIcon/>,
    title: "Mistakes are to be learned from, not feared, and not repeated",
  },
  {
    imageSrc: <AssignmentTurnedInIcon/>,
    title: "Discipline begins with oneself.",
  },
  {
    imageSrc: <AvTimerIcon/>,
    title: "Respect People, Work, Time",
  },
  {
    imageSrc: <MoreTimeIcon/>,
    title: "Put your 24 hours to good use, be productive",
  },
  {
    imageSrc: <QuestionMarkIcon/>,
    title: "Inquisitiveness is the key. Never stop asking why?",
  },
  {
    imageSrc: <ShowChartIcon/>,
    title: "Growth comes only from helping others grow.",
  },
  {
    imageSrc: <BubbleChartIcon/>,
    title: "Be better than yesterday",
  },
  {
    imageSrc: <PsychologyIcon/>,
    title: "Speak your mind. Never assume.",
  },
  {
    imageSrc: <AnimationIcon/>,
    title: "Understand and act",
  },


];


export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <SliderCard/>
      <ValueFeatures 
      heading={<>Our team makes the <span tw="text-primary-500 font-black">Difference</span></>}
      cards={cards}
      slider={true}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

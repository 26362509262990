import logo from '../../images/logo-icon.svg'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Excellence1 from 'images/Excellence/1.svg'
import Excellence2 from 'images/Excellence/2.svg'
import Excellence3 from 'images/Excellence/3.svg'

export const excellence = {
    Section1: {
        heading: "Appbay COE ",
        subheading: "In pursuit of excellence",
        description: <p>
        We, at Appbay, understand how important it is for organizations to have a Center of Excellence for CoE. This structure should provide efficiency, faster process turnaround times and greater value to customers. Appbay’s COE is led by experts in business transformation and operational excellence — our team possesses proven experience through hands-on work of real-life business challenges. With these competences, Appbay helps clients get more from setting up a CoE.
<br/><br/>Our team is composed of highly-skilled individuals who disseminate product knowledge, best practices and streamline access to technical standards and tools thus enabling self-sufficiency in an organization.Our Center of Excellence division for Appian and Blue Prism can offer a variety of engagement models for low-code and RPA tailored to the organization’s

        </p>,
        imgSrc: Excellence1,
        render: true,
        showButton: true,
        primaryButtonText: "Request Demo",
        primaryButtonUrl: "/contact-us/consultation"
    },
    Section2: {
        heading: "WHAT WE DO? ",
        subheading: "",
        description: <>
        <p>
        Helping build and maintain the app ecosystem, our Center of Excellence is your go-to partner for recommendations on all aspects of your projects. We will evaluate your requirements, recommend the right strategy and ensure you are conducting R&D in the most effective manner. Here’s a look at our Center of Excellence service offerings: 
            </p><br/>
        <ol style={{textAlign:'justify'}}>
           <li><ChevronRightIcon/> BPM fit assessment for a use case</li> 
           <li><ChevronRightIcon/> Project scoping and estimation</li> 
           <li><ChevronRightIcon/> Appian and Blue Prism solution architecture design</li> 
           <li><ChevronRightIcon/> R&D consulting for integrations, components in the Appian and Blue Prism ecosystem</li> 
           <li><ChevronRightIcon/> Process redesign, Health check reviews and performance tuning  for existing Appian and Blue Prism applications</li> 
           <li><ChevronRightIcon/> Proof of concept and prototypes development</li> 
           <li><ChevronRightIcon/> Enabling customer development teams on core Appian and Blue Prism skills</li> 
           <li><ChevronRightIcon/> Recommend the right RPA strategy</li> 
           <li><ChevronRightIcon/> Formulating and guiding customers with RPA best practices</li> 
           <li><ChevronRightIcon/> Training teams with skill sets for automation support</li> 
           <li><ChevronRightIcon/> Support organizations scale RPA automations</li> 
        </ol>
    </>,
    imgSrc: Excellence2,
        render: true
    },
Section3: {
    heading: "HIGHLIGHTS",
        subheading: "",
            description: <>
                <p></p><br />
                <ul>
                    <li><span><ChevronRightIcon/>  Flexible engagement models for architecture, development and design services &nbsp;</span></li>
                    <li><span><ChevronRightIcon/>  Bootstrap team set-up and work flexibly with clients in a phased manner&nbsp;</span></li>
                    <li><span><ChevronRightIcon/>  Access to Appian and Blue Prism domain experts backed by infrastructure and quality assurance specialists&nbsp;</span></li>
                    <li><span><ChevronRightIcon/>  Access to reusable templates, tools and accelerators</span></li>
                    <li><span><ChevronRightIcon/>  R&D on Emerging technologiess</span></li>
                    <li><span><ChevronRightIcon/>  Create a scalable, secure digital workforce that can be deployed across the entire range of services and processes</span></li>
                    <li><span><ChevronRightIcon/>  Analytics</span></li>
                    <li><span><ChevronRightIcon/>  BPM for end-to-end operational efficiency </span></li>
                </ul>
            </>,
                imgSrc: Excellence3,
                    render: true
},
Section4: {
    heading: "BENEFITS OF APPBAY'S COE",
        cards: [
            {
                imageSrc: <AcUnitIcon />,
                title: "",
                description: "Create an environment where everyone can thrive",

            },
            {
                imageSrc: <AccountTreeIcon />,
                title: "",
                description: "Create a better organizational structure without silo",

            },

            {
                imageSrc: <SelfImprovementIcon />,
                title: "",
                description: "Streamline operations and drive continuous improvement",

            },
            {
                imageSrc: <ModeStandbyIcon />,
                title: "",
                description: "The major goal is to provide support for our resources, enforce best practices and impart training in order to ensure their knowledge of our events.",

            },
            {
                imageSrc: <SupportAgentIcon />,
                title: "",
                description: "Providing our customers with quality products and services. Our goal is to meet or exceed our customers' expectations at all times.",

            },
        ],
            render: true
},
Section5: {
    heading: "THE APPBAY FACTOR",
        subheading: "",
            description: "Shouldn’t every business have a plan? If you need help developing one, we at Appbay help you create an actionable plan that will help you stay on track with your goals. As your partner, we bring a 360 Degree view of industry and competencies to the table. We do not believe in consulting you based on any predetermined framework, but rather, we try and solve problems using the industry best practices combined with our domain expertise.",
                imgSrc: logo,
                    render: false
},
}
import logo from '../../images/logo-icon.svg'
import MemoryIcon from '@mui/icons-material/Memory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Development1 from 'images/Development/1.svg'
import Development2 from 'images/Development/2.svg'
import Development3 from 'images/Development/3.svg'
import Development4 from 'images/Development/4.svg'
import Development5 from 'images/Development/5.svg'
import tw from "twin.macro";
import styled from "styled-components";

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 mx-auto flex-shrink-0 relative`;
const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`
]);



export const development = {
    Section1: {
        heading: "Development, Implementation and Support",
        subheading: "Accelerated development helps you to maximize business transformation results",
        description: "As a software consulting company, Appbay specializes in development and implementation of custom web applications. We provide our services to startups, small businesses and enterprises across India. We use state-of-the-art technologies to define, build and support effective solutions to solve client's business problems. Appbay manages entire software delivery lifecycle while it focuses on architecture, design and development best practices.",
        imgSrc: Development1,
        render: true,
        showButton: true,
        primaryButtonText: "Request Demo",
        primaryButtonUrl: "/contact-us/consultation"
    },
    Section2: {
        heading: "WHAT WE DO?",
        subheading: "",
        description: <>
            <strong>Architecting the implementation</strong><br />
            <p>Our enterprise architecture services empower organizations to leverage automation, artificial intelligence and machine learning. With us you can envision and realize the future of technology-enabled organization. We adopt best practices to discover the processes and technology required to help you develop an IT strategy that is aligned with your business goals.
            </p><br />
            <strong>Capacity Planning</strong><br />
            <p>Capacity planning is a process to find how much resources are available to support your business. Appbay experts help you in assessing, prioritizing and allocating appropriate resources to each project. We also analyze patterns in resource utilization, estimate costs and provide suggestions to improve resource utilization
            </p><br />
        </>,
        imgSrc: Development2,
        render: true
    },
    Section3: {
        heading: "",
        subheading: "",
        description: <div tw='flex flex-col'>
            <div tw="flex flex-row-reverse justify-between items-center mb-16">
                <Image tw="w-1/2 pl-10" src={Development3} imageRounded={true} />
                <p>
                    <strong>Development</strong><br />
                    Our design and development team give the aesthetics a central position in any application we create. We follow the latest mobile trends and design accordingly, by building apps that are very easy to use. With our expertise, we ensure your creation is robust and up-to-date with all the features your customer wants. Our development teams help you, through processes that are developed with innovative solutions to build applications that have seamless workflows, quick and easy features. With Appbay at your side, your customers have a high quality user experience when using any of your creations.
                </p>
            </div>
            <div tw="flex flex-row mb-16">
                <Image tw="w-1/2 pr-10" src={Development4} imageRounded={true} />
                <p>
                    <strong>Quality Engineering</strong><br />
                    Appbay provides a higher quality of software and more with automated testing, reducing the time to market. Quality Engineering & Assurance (QEA) is the mantra at AppClarity. Our Appbay Automation Testing Tool is a set of intelligent frameworks that enables 100% automation while performing end-to-end functional testing of Appian applications. Not only does it reduce time to market by enabling quick release cycles but with Appbay you have the right information at right time, so you can make better decisions about your project and deliver working applications in less time for end users.
                </p>
            </div>
            <div tw="flex flex-row-reverse mb-10">
                <Image tw="w-1/2 pl-10" src={Development5} imageRounded={true} />
                <p>
                    <strong>Support - SWAT</strong><br />
                    Manage the lifecycle of your digital business with AppBay’s Support and Enhancement support packages. Our dedicated Appian consultants for fixing applications, flexible support models, dedicated point of contact for every project, we ensure you get the right assistance always.</p>
            </div>
        </div>,
        // imgSrc: Development3,
        render: true,
        image: false
    },
    Section4: {
        heading: "",
        cards: [
            {
                imageSrc: <MemoryIcon />,
                title: "Reduce compliance errors",
                description: "",

            },
            {
                imageSrc: <TrackChangesIcon />,
                title: "Increase ROI",
                description: "",

            },
            {
                imageSrc: <OpenInFullIcon />,
                title: "Access valuable training",
                description: "",

            },
            {
                imageSrc: <AttachMoneyIcon />,
                title: "Receive ongoing technical support",
                description: "",

            },
            {
                imageSrc: <AttachMoneyIcon />,
                title: "Improve productivity",
                description: "",

            },
        ],
        render: false
    },
    Section5: {
        heading: "WHY WORK WITH US?",
        subheading: "",
        description: "Appbay is all about empowering organizations to think wider, work smarter and deliver impact. We have the in-depth business and technology expertise to help you design and deliver applications for your business with confidence        ",
        imgSrc: logo,
        render: true
    },
}
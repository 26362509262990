import React, { useState } from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";


import ShieldIconImage from "../../images/shield-icon.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";



const Container = tw.div`relative pb-5`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-5`}
`;
const Heading = tw(SectionHeading)``;
const Content = tw.div`max-w-screen-xl mx-auto `;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex py-10`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-auto py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-8 flex-shrink-0 mx-auto`}
    img {
      ${tw`w-full h-full`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none text-center`}
  }

  .digit {
    ${tw`tracking-wider font-bold text-3xl leading-none text-center`}
  }

  .description {
    ${tw`mt-5 font-normal text-gray-700 leading-snug`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

export default (props) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */





  const [sliderRef, setSliderRef] = useState(null);

  const [displayCards, setDisplayCards] = useState(props.cards.slice(0, 6))

  const [slide, setSlide] = useState(1)

  function changeSlides(slider) {
    if (slider === "right") {
      setDisplayCards(props.cards.slice(6, 12))
      setSlide(2)
    } else {
      setDisplayCards(props.cards.slice(0, 6))
      setSlide(1)
    }
  }

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>{props.heading}</Heading>
          {
            props.slider
              ?
              <Controls>
                <PrevButton onClick={() => changeSlides("left")}><ChevronLeftIcon /></PrevButton>
                <NextButton onClick={() => changeSlides("right")}><ChevronRightIcon /></NextButton>
              </Controls>
              : null
          }
        </HeadingWithControl>
      </Content>
      <ThreeColumnContainer>
        {displayCards.map((card, i) => (
          <Column key={i} ref={setSliderRef}>
            <Card>
              <span className="imageContainer">
                {/* <img src={card.imageSrc || defaultCardImage} alt="" /> */}
                {card.imageSrc}
                {/* <span className="digit">{slide == 1 ? i + 1 : i + 7}</span> */}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span><br />
                <span className="description">{card.description}</span>
              </span>
            </Card>
          </Column>
        ))}
        {
          window.innerWidth < 768 && props.slider
            ?
            <Controls>
              <PrevButton onClick={() => changeSlides("left")}><ChevronLeftIcon /></PrevButton>
              <NextButton onClick={() => changeSlides("right")}><ChevronRightIcon /></NextButton>
            </Controls>
            : null
        }
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};

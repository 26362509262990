import logo from '../../images/logo-icon.svg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import AllOutIcon from '@mui/icons-material/AllOut';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import CRM1 from 'images/CRM/1.svg'
import CRM2 from 'images/CRM/2.svg'

export const crm = {
    Section1:{
        heading : "CUSTOMER RELATIONSHIP MANAGEMENT",
        subheading : "Acquire, Retain and Grow Customer Relationship",
        description : "Customer Relationship Management (CRM) is a corporate philosophy that involves understanding the needs of the customers, creating customer value and building customer relationships; aiming to maximize company profits. CRM is different from just marketing .It encompasses the entire process of managing the customer for long term business interactions and creating customer value across every customer touch-point in order to ensure customer retention, build customer loyalty and achieve high levels of profit and retention.",
        imgSrc: CRM1,
        render: true
    },
    Section2:{
        heading : "PROVIDING 360-degree view of the customer ",
        subheading : "",
        description : "Appbay holds expertise in enterprise customers engagement and provides a 360-degree view of the customer. Salesforce CRM is an integrated Customer relationship management (CRM) software application, allowing businesses to track and analyze data about their customers. With our robust CRM capabilities, Appbay facilitates sales and marketing departments for more revenue generation by providing timely insights and information about every customer contact.",
        imgSrc: CRM2,
        render: true
    },
    Section3:{
        heading : "Salesforce : A Market Leader in CRM",
        subheading : "",
        description : "Salesforce leads the market for cloud-based CRM solutions with its innovative suite of intelligent products. The suite is designed to empower your sales and marketing teams to succeed at every stage of the buyer's journey. Whatever your role, you can now close more deals—faster. With built-in AI, Salesforce offers a complete CRM solution that is scalable and affordable.",
        imgSrc: "https://www.salesforce.com/news/wp-content/uploads/sites/3/2021/05/Salesforce-logo.jpg?w=264&h=264&crop=1",
        render: true
    },
    Section4:{
        heading : "Why cloud-based CRM?",
        cards : [
            {
              imageSrc: <CloudSyncIcon/>,
              title: "Anywhere & Anytime Access",
              description: "",
              
            },
            {
              imageSrc: <AttachMoneyIcon/>,
              title: "Cost-Effective",
              description: "",
              
            },
            {
              imageSrc: <AllOutIcon/>,
              title: "Flexible",
              description: "",
              
            },
            {
              imageSrc: <InstallDesktopIcon/>,
              title: "Easy Installation",
              description: "",
              
            },
          ],
        render: true
    },
    Section5:{
        heading : "THE APPBAY FACTOR",
        subheading : "",
        description : "Appbay provides innovative customer relationship management solutions that deliver optimal client relationship management. The Appbay CRM solution is affordable, easy to deploy and maintain and has an unmatched uptime record. Our customer facing  marketing software allows further enhancement of the customers experience by delivering seamless 'Conversation' between the sales, support, marketing and customer success teams.",
        imgSrc: logo,
        render: true
    },

}
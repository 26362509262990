import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import { Container } from '@mui/material';
import { SectionHeading, Subheading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import tw from "twin.macro";
import Chip from '@mui/material/Chip';

const Heading = tw(SectionHeading)`w-full lg:mt-8 py-5 mb-5`;

function nextWeekend(date){
  var  days = {Sun:0,Mon:1,Tue:2,Wed:3,Thu:4,Fri:5,Sat:6}
  var dat = date
  console.log(new Date(date.getFullYear(),date.getMonth(),dat.getDate()+1))
  while(dat.getDay() != 6){
    const next = dat.getDate()+1
    dat = new Date(date.getFullYear(),date.getMonth(),next)
  }
  return dat
}

function firstDayofWeek(y,m,dy) {
  var  days = {Sun:0,Mon:1,Tue:2,Wed:3,Thu:4,Fri:5,Sat:6}
  var dat = new Date(y,m,1)
  var currentmonth = m
  while(dat.getDay() != days[dy]){
    const next = dat.getDate()+1
    dat = new Date(y,m,next)
     }
     const nextDat = new Date(y,m,dat.getDate()+7)
   return [dat,nextDat];
  }

  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

function createData(name,day) {
  var currentYear= new Date().getFullYear(); 
  var currentMonth= new Date().getMonth(); 
  const arr = firstDayofWeek(currentYear,currentMonth,day)
  // console.log(temp)
  // const date = new Date()
  // const arr = [date,date,date]
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const weekend = nextWeekend(new Date())
  return {
    name,
    history: [
      {
        startDate: `${pad(arr[0].getDate())}/${pad(arr[0].getMonth()+1)}/${arr[0].getFullYear()}`,
        days: `${day} (Mon - Fri)`,
        duration: 3,
        batchTime: '8AM - 9AM IST',
        weekend: false
      },
      {
        startDate: `${pad(weekend.getDate())}/${pad(weekend.getMonth()+1)}/${weekend.getFullYear()}`,
        days: 'Sat (Sat - Sun)',
        duration: 3,
        batchTime: '8AM - 9AM IST',
        weekend: true
      },
      {
        startDate: `${pad(arr[1].getDate())}/${pad(arr[1].getMonth()+1)}/${arr[1].getFullYear()}`,
        days: `${day} (Mon - Fri)`,
        duration: 3,
        batchTime: '8AM - 9AM IST',
        weekend: false
      },

    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <span tw="font-bold text-xl">{row.name}</span>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align='center'><span tw="text-lg font-bold">Start Date</span></TableCell>
                    <TableCell align='center'><span tw="text-lg font-bold">Days</span></TableCell>
                    <TableCell align='center'><span tw="text-lg font-bold">Duration</span></TableCell>
                    <TableCell align='center'><span tw="text-lg font-bold">Batch Time</span></TableCell>
                    {/* <TableCell align="center">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align='center' component="th" scope="row">
                        {historyRow.startDate}
                      </TableCell>
                      <TableCell align='center'>{historyRow.days}&nbsp;&nbsp;&nbsp;
                        {historyRow.weekend
                          ?
                          <Chip label="Weekend" size="small" sx={{backgroundColor:'#f6c453'}} />
                          : null
                        }
                      </TableCell>
                      <TableCell align='center'>{historyRow.duration} Weeks</TableCell>
                      <TableCell align='center'>{historyRow.batchTime}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Low-Code Tech Stack','Mon'),
  createData('Salesforce - Development','Tue'),
  createData('Salesforce - Admin and app builder','Wed'),
  createData('Salesforce - Lightning component development','Thu'),
  createData('Outsystems','Fri'),


];

export default function Timetable() {
  return (
    <Container tw="mb-10">
      <Heading>Upcoming <span tw="text-primary-500 font-black">Trainings</span></Heading>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">

          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

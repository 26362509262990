import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import ComingSoon from "images/Coming Soon-01.png";

import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "./components/headers/light";
 
/* Hero */


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-black`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-900 hover:text-primary-500 mx-5`}
  }
`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl
    document.body.appendChild(iframe);
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 px-8">
      <StyledHeader />
        <Content2Xl>
           <img alt="coming-soon" src={ComingSoon} height={window.innerHeight} width={window.innerHeight} style={{marginLeft:"auto",marginRight:"auto"}}/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

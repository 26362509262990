import pdf from '../../pdf/Appbay - Case Study For Insurance Domain.pdf'
import Insurance1 from 'images/Insurance.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


export const Insurance = {

    heading: "Insurance",
    subheading: "",
    description: <>
    <strong>Client : </strong>Insurance customer<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Less Operational efficiency. </p>
    <p><ChevronRightIcon/> The architecture including COTS and SaaS products, on-premises legacy systems, and multiple Cloud environments resulting into huge IT investment and lot of complexity. There was no centric orchestration layer that could connect and show 360 view of all systems on single screen.  </p>
    <p><ChevronRightIcon/> Very less agility </p>
    <br/>
    <strong>Solution : </strong> Created multiple apps that not only improved operational efficiency, increased sales enablement to higher level but also helped in innovating other aspects of the company to help them support in COVID era. Silos of excels were replaced by full suite of office solutions created to help both their customers and reps locate COVID-testing sites and information.
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> Gain 4x Agility Boost </p>
    <p><ChevronRightIcon/> Improved end-user experience and productivity </p>
    <p><ChevronRightIcon/> Centric tracker view to enable operational manager to take decisions based on the collaborative relational indicators. </p>
    </>,
    imgSrc: Insurance1,
    pdf: pdf

}
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled.div`md:w-5/12 flex-shrink-0 h-1/6`;

const TextContent = tw.div`lg:py-5 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl  text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-10 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
  ]);

export default function SimpleSlider() {
   

    var style = {
        img: {
            height: '80%',
            width: '80%',
            marginRight: 'auto',
            marginLeft:'auto'
        },
        image:{
            height: '400px',
            objectFit:'cover',
            
        }
    }
    return (
        <Container tw="py-1">
            <TwoColumn>
                <Column>
                    <Carousel style={style.img}>
                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://g.foolcdn.com/image/?url=https%3A%2F%2Fg.foolcdn.com%2Feditorial%2Fimages%2F614226%2Fappian.jpg&w=700&op=resize"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>Appian</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>

                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://content.timesjobs.com/photo/79133964/trending/salesforce-is-hiring-check-the-details-here.jpg"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>Salesforce</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>

                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://curity.io/images/resources/tutorials/integration/tutorials-apigee.png"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>APIGEE</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>

                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://lh4.googleusercontent.com/sBaxDLU9jP8BOaB8vNld8Yu_dv7V3HZGBNBHiguET93-VXWxm1tO3J6PtAWEg46cBAicYGZtZEMwRdYO3NYJUKBrEIT18-KvRUAMIHzQ_Q1sagcKZa3hyKVR4hJaf4VpTV3hoAoa"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>ReactJS</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://mariadb.com/wp-content/uploads/2018/10/mariadb-social-share.jpg"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>Maria DB</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://i.morioh.com/210608/8f995962.webp"
                                alt="Mysql"
                            />
                            {/* <Carousel.Caption>
                                <h3>Mysql</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://wallpapercave.com/wp/wp6599799.jpg"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>Java</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item >
                            <img
                                className="d-block w-100"
                                src="https://c4.wallpaperflare.com/wallpaper/873/975/781/python-programming-minimalism-grey-technology-hd-wallpaper-preview.jpg"
                                alt="First slide"
                                style={style.image}
                            />
                            {/* <Carousel.Caption>
                                <h3>Python</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>

                    </Carousel>
                </Column>
                <TextColumn textOnLeft={false}>
                    <TextContent>
                        <Subheading></Subheading>
                        <Heading>There's Nothing Better Than Getting Better.</Heading>
                        <Description></Description>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container >
    );
}
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ConsultationTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const content = [
        {
            label: "Process discovery",
            content: "Implementing new and improved business processes throughout your organization will help you maintain quality levels and response times, reduce costs associated with errors and unnecessary rework, improve customer service and grow your business. Our process discovery consulting services can help you understand your organizational needs better than ever before. By closely examining how your systems interact and contribute to the flow of business throughout your organization, we can identify the options that are right for you"
        },
        {
            label: "Process modeling",
            content: "Process modeling is a strategic transformation methodology. We prepare graphical representations of your organization’s business processes and workflows to identify potential improvements. Requirement engineering and business analysis are a part of the process."
        },
        {
            label: "Process analysis",
            content: "By identifying tasks that may be automated, user actions that hinder processes, identifying obstacles that cause delays, and getting robust data on how they are performing, our team gets reliable data on how processes are performing."
        },
        {
            label: "Process redesign",
            content: "We aim to help you decrease errors and costs, improve accuracy, and generate tremendous amounts of value in a sustainable manner. Whether you're looking to reduce manual activities like data entry, information verification, or file management, Process Redesign is your best solution."
        },
        {
            label: "Process documentation",
            content: "Process Documentation is the result of accumulated knowledge. It provides a reliable basis for understanding an organization's processes and to support their further optimization in order to increase performance and compliance reliability."
        },
        {
            label: "Continuous Improvement",
            content: "Organizations and people associated with them will not be perfect, nor will they be 100% efficient at all times. This is because they are constantly changing, learning, and adapting to new things. To ensure that continuous improvement impinges on a better and healthier system, we need barriers like our budget, technology/equipment and resources. The 'Continuous Improvement' is an ongoing effort that seeks to improve the processes overtime."
        },
        {
            label: "Hyper Automation assessment",
            content: "Hyper Automation assessment is the process of defining strategies for the automation program, identifying best practices and business use cases, planning resources, and identifying the right hyper automation technology. The hyper automation assessment must address a company's entire lifecycle in terms of automating what needs to be automated, and when automation will be needed."
        },
    ]

    const mobile = window.innerWidth<768
    return (
        <div style={{border:'2px solid #F6C453',marginLeft:'auto',marginRight:'auto',width:mobile?'100%':'80%',marginTop:mobile?10:20 }}>
            <Box sx={{  marginTop: 1,padding:1  }} tw="mt-10">
                <Tabs
                    value={value} onChange={handleChange} variant={window.innerWidth >= 1024 ? "fullWidth" : "scrollable"} scrollButtons={true} centered TabIndicatorProps={{ style: { background: '#F6C453' } }}
                >
                    {
                        content.map((tab, index) => {
                            return (
                                <Tab value={index} style={{ fontWeight: 'bolder', color: '#1A202C' }} label={tab.label} />
                            )
                        })
                    }
                </Tabs>
            </Box>
            <Box sx={{textAlign:'center',backgroundColor:'#E6E8EA',marginTop:2}}>
                {
                    content.map((tab, i) => {
                        return (

                            <TabPanel value={value} index={i}>
                                {tab.content}
                            </TabPanel>
                        )
                    })
                }
            </Box>



        </div>
    )
}

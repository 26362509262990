import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import ConsultationTabs from "content/Service By Capablities/ConsultationTabs";
import { useParams } from "react-router-dom";

const Container = tw.div`relative my-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 mx-auto flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw` mt-10 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div` text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl  text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-justify px-3  text-sm lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
  tabs = false,
  halfImage = false,
  extraContent = null,
  image = true,
  imageAlt,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Register",
      description: "Create an account with us using Google or Facebook.",
    },
    {
      heading: "Download",
      description:
        "Browse and Download the template that you like from the marketplace.",
    },
    {
      heading: "Run",
      description:
        "Follow the instructions to setup and customize the template to your needs.",
    },
  ];

  if (!steps) steps = defaultSteps;
  let { contentRef } = useParams();

  return (
    <Container>
      <TwoColumn>
        {image ? (
          <ImageColumn>
            {halfImage ? (
              <Image
                tw="mx-auto w-1/3"
                alt={imageAlt}
                src={imageSrc}
                imageBorder={imageBorder}
                imageShadow={imageShadow}
                imageRounded={imageRounded}
              />
            ) : (
              <Image
                tw="mx-auto w-96"
                alt={imageAlt}
                src={imageSrc}
                imageBorder={imageBorder}
                imageShadow={imageShadow}
                imageRounded={imageRounded}
              />
            )}
            {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
          </ImageColumn>
        ) : null}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            {/* <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps> */}
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      {tabs ? <ConsultationTabs /> : null}
      {extraContent}
    </Container>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import tw from "twin.macro";
import HeaderBase from "../components/headers/light.js";
import ValueFeatures from "components/features/DashedBorderSixFeatures";
const Header = tw(HeaderBase)`max-w-none -mt-8 -mx-8 px-8`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <img
        src="/capability.jpeg"
        alt="hero"
        width={"100%"}
        style={{
          "object-fit": "cover",
          height: "250px",
          marginBottom: "20px",
        }}
      />
      <div>
        <ValueFeatures
          heading={
            <>
              Our
              <span tw="text-primary-500 font-black"> Capabilities</span>
            </>
          }
          cards={[]}
          slider={false}
        />
        <div
          style={{
            display: "flex",
            "justify-content": "space-evenly",
            "align-items": "baseline",
            flexWrap: "wrap",
            margin: "-50px 10px 80px",
            textAlign: "center",
          }}
        >
          <a
            href="/Appbay_Case_Studies.zip"
            target="__blank"
            download={true}
            style={{
              margin: "20px",
            }}
          >
            <img
              src="/caseStudy.jpg"
              alt="case study"
              width={120}
              style={{ margin: "10px auto" }}
            />
            Case Studies{" "}
            <img
              src="/download.png"
              alt="download case study"
              width={20}
              style={{ display: "inline" }}
            />
          </a>
          <a
            href="/Appbay Tech Capability Deck.pdf"
            target="__blank"
            download={true}
            style={{
              margin: "20px",
            }}
          >
            <img
              src="/deck.jpg"
              alt="Capability Deck"
              width={120}
              style={{ margin: "10px auto" }}
            />
            Capabilities Deck{" "}
            <img
              src="/download.png"
              alt="download Capability Deck"
              width={20}
              style={{ display: "inline" }}
            />
          </a>
          <a
            href="https://training.appbaytech.com/"
            download={true}
            target="__blank"
            style={{
              margin: "20px",
            }}
          >
            <img
              src="/workshop.png"
              alt="Capability Deck"
              width={120}
              style={{ margin: "10px auto" }}
            />
            Corporate Training{" "}
            <img
              src="/link.png"
              alt="download Capability Deck"
              width={15}
              style={{ display: "inline" }}
            />
          </a>
        </div>
      </div>
      <hr style={{ margin: "20px 0", background: "#d5cece" }} />{" "}
      <div>
        <ValueFeatures
          heading={
            <>
              Our
              <span tw="text-primary-500 font-black"> Solutions</span>
            </>
          }
          cards={[]}
          slider={false}
        />
        <div
          style={{
            display: "flex",
            "justify-content": "space-evenly",
            "align-items": "baseline",
            flexWrap: "wrap",
            margin: "-50px 10px 80px",
            textAlign: "center",
          }}
        >
          <a
            href="Digital Transformation - Real Estate Management.pdf"
            download={true}
            target="__blank"
            style={{
              margin: "20px",
            }}
          >
            <img
              src="/realEstate.jpg"
              alt="Digital Transformation"
              width={200}
              style={{ margin: "10px auto" }}
            />
            Real Estate Management{" "}
            <img
              src="/download.png"
              alt="download Digital Transformation"
              width={20}
              style={{ display: "inline" }}
            />
          </a>
          <a
            href="/Loan Management Solution.pdf"
            target="__blank"
            download={true}
            style={{
              margin: "20px",
            }}
          >
            <img
              src="/loan.png"
              alt="loan management"
              width={120}
              style={{ margin: "10px auto" }}
            />
            Loan Management System{" "}
            <img
              src="/download.png"
              alt="download case study"
              width={20}
              style={{ display: "inline" }}
            />
          </a>
          <a
            href="https://community.appian.com/b/appmarket/posts/smart-appian-technical-support-and-training-system"
            target="__blank"
            download={true}
            style={{
              margin: "20px",
              width: "250px",
            }}
          >
            <img
              src="/training.png"
              alt="Capability Deck"
              width={120}
              style={{ margin: "10px auto" }}
            />
            Smart Appian Technical Support and Training System{" "}
            <img
              src="/link.png"
              alt="download Capability Deck"
              width={15}
              style={{ display: "inline" }}
            />
          </a>
        </div>
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/hero";
import HeroMobile from "components/hero/BackgroundAsImage";
import DomainExpertise from "components/features/TwoColSingleFeatureWithStats";
import HelpYou from "components/features/TwoColSingleFeatureWithStats2.js";
import Promise from "components/features/DashedBorderSixFeatures";
import CardFeatures from "components/features/ThreeColSimple.js";
import Footer from "components/footers/FiveColumnWithInputForm";
import "../components/hero/Hero.css";
import Header from "../components/headers/light";
import TrainingInfo from "components/features/TwoColWithButton";
import TrainingSVG from "images/Training.svg";
import Appian from "images/Appian-Logo.png";
import LogoSlider from "components/carasoual/LogoSlider";

const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`inline-block mt-8`;
const primaryButtonUrl ="https://appian.com/"

export default () => (
  <AnimationRevealPage>
    {window.innerWidth < 768 ? (
      <HeroMobile />
    ) : (
      <>
        <Header />
        <Hero
          style={{
            padding: 0,
          }}
        />
      </>
    )}
    <div
      style={{
        paddingTop: window.innerWidth < 768 ? 0 : window.innerHeight + 20,
      }}
    >
      <HelpYou />
      <TrainingInfo
        heading={
          <>
          Our          <wbr />{" "}
          <span tw="text-primary-500 font-black ">
            Partners{" "}
          </span>
        </>
        }
        description={
          <>
            <Description>
              Appian helps organizations build apps and workflows rapidly, with
              a lowcode automation platform. Combining people, technologies, and
              data in a single workflow, Appian can help companies maximize
              their resources and improve business results. Many of the world’s
              largest organizations use Appian applications to improve customer
              experience, achieve operational excellence, and simplify global
              risk management and compliance.
            </Description>
          </>
        }
        buttonRounded={false}
        textOnLeft={false}
        // primaryButtonText="Explore more"
        RedirectUrl ={primaryButtonUrl}
        // primaryButtonUrl = {primaryButtonUrl}
        imageSrc={Appian}
        showButton={false}
        imageCss={imageCss}
        imageDecoratorBlob={false}
        imageAlt="upskill"
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      <DomainExpertise />
      <TrainingInfo
        heading={
          <>
            Upskill in
            <wbr />{" "}
            <span tw="text-primary-500 font-black ">
              Trending Technologies{" "}
            </span>
          </>
        }
        description={
          <>
            <Description>
              Face the future with confidence and with the skills and tools to
              thrive.
            </Description>
            <LogoSlider />
          </>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Explore more"
        imageSrc={TrainingSVG}
        showButton={true}
        imageCss={imageCss}
        imageDecoratorBlob={false}
        imageAlt="upskill"
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <CardFeatures />
      <Footer />
    </div>
  </AnimationRevealPage>
);

import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
    LogoLink as LogoLinkBase,
    NavLinks,
    NavLink as NavLinkBase,
    PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/logo-light.svg";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";



const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 py-10 bg-primary-200 text-gray-900`;
const Header = tw(HeaderBase)
    `max-w-none -mt-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)
    `lg:text-gray-900 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)
    `text-gray-900 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)
    `shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)
    `py-5`;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)
    `mt-5 lg:mt-16 lg:ml-16`;
const Heading = tw(SectionHeading)
    `max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)
    `mt-4 max-w-2xl text-gray-900 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)
    `mt-8 text-sm sm:text-base px-6 py-3 mt-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-1/2 ml-20 mb-10`

export default ({
    heading = "About Appbay",
    description = "Your trusted Digital Transformation Partner",
    primaryButtonText = "Request Demo",
    primaryButtonUrl = "contact-us/consultation",
    imageSrc = logoImageSrc,
    imageAlt = "logo"
}) => {


    return (
        <>
                <Header/>
        <PrimaryBackgroundContainer >
            <Content2Xl >
                <Container >
                    <Row >
                        <IllustrationColumn >
                            <Image src={imageSrc} alt={imageAlt}/>
                        </IllustrationColumn >
                        <TextColumn >
                            <Heading > {heading} </Heading>
                            <Description > {description} </Description>
                            <PrimaryButton as="a" href={primaryButtonUrl} > {primaryButtonText} </PrimaryButton>
                        </TextColumn >
                    </Row>
                </Container >
            </Content2Xl>
        </PrimaryBackgroundContainer >
        </>
    );
};
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import BalanceIcon from '@mui/icons-material/Balance';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "",
  heading = "Why to Join Appbay?",
  testimonials = [
    {
      imageSrc:
      <BalanceIcon/>,
      quote:
        "Here at Appbay, we believe in the ease of work, which enables our team to deliver world-class solutions for our esteemed clients.",
      customerName: "Work Life Balance"
    },
    {
      imageSrc:
      <CelebrationIcon/>,
      quote:
        "We believe that happy and healthy employees can deliver an exceptional solution. Therefore, we invest a lot to make the workplace enjoyable.",
      customerName: "Fun at Work"
    },
    {
      imageSrc:
      <LightbulbIcon/>,
      quote:
        "Our world-class training modules enable our team to be skilled as per the advanced technology standards. We motivate our team to scale-up",
      customerName: "Learning & Development"
    }
  ]
}) => {
  return (
    <Container >
      
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                {testimonial.imageSrc}
                <CustomerName>{testimonial.customerName}</CustomerName>
                {/* <Quote>"{testimonial.quote}"</Quote> */}
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

import logo from '../../images/logo-icon.svg'
import MediationIcon from '@mui/icons-material/Mediation';
import WebIcon from '@mui/icons-material/Web';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LanguageIcon from '@mui/icons-material/Language';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import UIUX1 from 'images/UIUX/1.svg'
import UIUX2 from 'images/UIUX/2.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const replaceCommaLine=(data)=> {
  let dataToArray = data.split('|')
  dataToArray.map((line,i)=>{
    if(i!=0){
      dataToArray[i] = <p><ChevronRightIcon/> {line}<br/></p>
    }
  })
  return(dataToArray)

}

export const uiux = {
    Section1:{
        heading : "UX DESIGN ",
        subheading : "Intuitive & Interactive User experiences",
        description : "UX Design (User Experience Design) is the design process of creating a practical and usable system that provides an exceptional user experience. UED is an essential tool for organizations to fulfill their business innovation, providing a new path to attain competitive advantage by emphasising the importance of user satisfaction. UED not only concerns on how to satisfy customers and users via offering them easier access and discover, but also weighs considerations about ease-of-use for end-users' daily web surfing, searching and information management activities.        ",
        imgSrc: UIUX1,
        render: true
    },
    Section2:{
        heading : "UX DESIGN - Key Aspect in Digital Experience",
        subheading : "",
        description : replaceCommaLine("Intelligent UXD provides - |High user retention: Offering the customers  Smooth animations, scrolling, and navigations can go a long way as it  keeps the experience silky smooth.Features like caching, and push notification gives a native like  feel to the users.|Security and Discoverability : Data security in terms of mobile apps and secure connections over the network in terms of web apps are the core of smart UXDs . |Reduced development costs and time: Good Designing goes through phases like research, Predictive and Prescriptive Analysis and testing.  These steps help eliminate the errors at an early stage of development which results in lower cost as more the bug stays in the system, higher is the cost of fixing it.|Accessibility:: Accessibility is the  most critical element to improve  the user experience. UXD with better accessibility is Perceivable, Operable, and robust"),
        imgSrc: UIUX2,
        render: true
    },
    Section3:{
        heading : " THE UI/UX BAY",
        subheading : "",
        description : "Appian offers a revolutionary low-code development platform that empowers non-technical users to as build a powerful applications within minutes. With Appian’s seamless platform, you can create and deploy custom applications within weeks not months – so you can do more with less time and money! Vuram leverages the phenomenal capabilities of this platform to streamline workflows, build custom applications and help our clients get more done for less!",
        imgSrc: "https://appian.com/content/dam/appian-aem/appian-logo-meta-image.png",
        render: false
    },
    Section4:{
        heading : "THE UI/UX BAY",
        cards : [
            {
              imageSrc: <MediationIcon/>,
              title: "Native",
              description: "",
              
            },
            {
              imageSrc: <WebIcon/>,
              title: "Hybrid Packaged Web",
              description: "",
              
            },
            {
              imageSrc: <IntegrationInstructionsIcon/>,
              title: "Cross platform",
              description: "",
              
            },
            {
              imageSrc: <LanguageIcon/>,
              title: "Web Based",
              description: "",
              
            },
            {
              imageSrc: <AppShortcutIcon/>,
              title: "PWA",
              description: "",
              
            },
          ],
        render: true
    },
    Section5:{
        heading : "THE APPBAY FACTOR",
        subheading : "",
        description : "We at Appbay, have always believed that a unique design thinking approach to user-centric design is the most important thing we can do to create a successful product. This meticulous process helps us create products and experience that are engaging and customer centric.",
        imgSrc: logo,
        render: true
    },

}
import pdf from '../../pdf/Appbay - Case Study For Governance Domain.pdf'
import Governance1 from 'images/Governance.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Governance = {

    heading: "Governance",
    subheading: "",
    description: <>
    <strong>Client : </strong>Governance<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Silos of data in multiple excels. </p>
    <p><ChevronRightIcon/> Needed business processes to get automated to reduce the approval time </p>
    <p><ChevronRightIcon/> Needed 360 view of all the subsidiaries helping to monitor status of all the business processes </p>
    <br/>
    <strong>Solution : </strong> Application enabled creating and editing for all the business approval cycles with transparency. It also contained 360 view that provided centric view for organizational head to see the seamlessly integrated data from legacy systems and relevant resources.
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> Centralized tracking, simplify creating and management of all the business proposals. </p>
    <p><ChevronRightIcon/> Eliminated error and business process approval delay </p>
    </>,
    imgSrc: Governance1,
    pdf: pdf

}
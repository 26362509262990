import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Container, Divider, Paper } from '@mui/material';
import tw from "twin.macro";
import { SectionHeading } from 'components/misc/Headings';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Text = tw.p`text-xl font-medium`


export default function CheckBoxDemo() {
    const content = [
        "I want to be job ready in less than 3 weeks for top MNC's",
        "I don't have coding background but want to switch to IT industry in top tech-stacks.",
        "I want to start my freelancing journey, but don't know the do-how.",
        "I want to upskill myself in Automation, ML, AI.",
        "I am job ready and seeking active oppurtunities in low-code space.",
        "I am a freelancer and want to join the freelancers community to get more clients!"
    ]
    return (
        <Container>
            <SectionHeading tw='my-5 text-4xl'>Do you want any of this? -  Please Check All Boxes Where Your Answer Is YES!</SectionHeading>
            <div tw='flex flex-row flex-wrap flex-grow justify-center'>
                {
                    content.map((text, i) => {
                        return (
                            <Paper tw='flex flex-row items-center w-full lg:w-1/3 my-1 lg:my-5 mx-2 p-3'>
                                <Checkbox
                                    {...label}

                                    sx={{
                                        '& .MuiSvgIcon-root': { fontSize: 35 },
                                        color: '#F6C453',
                                        marginRight: 1,
                                        '&.Mui-checked': {
                                            color: '#F6C453',
                                        }
                                    }}
                                />
                                <Text>{text}</Text>
                            </Paper>
                        )
                    })
                }
            </div>
            <SectionHeading tw='my-5 text-2xl text-primary-500'>If you checked ANY of the boxes above, you are definitely interested in what's below! </SectionHeading>
            <Divider/>
        </Container>
    );
}

import logo from '../../images/logo-icon.svg'
import MemoryIcon from '@mui/icons-material/Memory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Quality1 from 'images/Quality/1.svg'
import Quality2 from 'images/Quality/2.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


export const quality = {
    Section1: {
        heading: "QUALITY ENGINEERING AND ASSURANCE ",
        subheading: "Accelerate transformation with speed, accuracy and agility ",
        description: "At  Appbay, we are passionate about Quality. It is the foundation of our business and we are committed to delivering Quality Engineering and Assurance services that leverage the latest technology, methodologies and process improvements to our clients. Our QE & QA consultants help companies develop cohesive QE & QA methodologies that address their software development needs, while leveraging their competencies across multiple delivery and testing environments in a flexible manner.",
        imgSrc: Quality1,
        render: true,
        showButton: true,
        primaryButtonText: "Request Demo",
        primaryButtonUrl: "/contact-us/consultation"
    },
    Section2: {
        heading: "WHAT WE DO? ",
        subheading: "",
        description: <>
        <p>Appbay’s services spans across the entire spectrum of Quality Engineering and Assurance, right from Functional Testing to Security Testing. Our committed, humble and passionate people ensure top quality and reliability across the complete development life cycle.</p>
        <br/><ol>
            <li><ChevronRightIcon/> Functional Testing</li>
            <li><ChevronRightIcon/>Automation Testing</li>
            <li><ChevronRightIcon/>Regression Testing</li>
            <li><ChevronRightIcon/>Security Testing</li>
            <li><ChevronRightIcon/>Performance Testing</li>
        </ol><br/>
        </>,
        imgSrc: Quality2,
        render: true
    },
    Section3: {
        heading: "WHY WORK WITH US?",
        subheading: "",
        description: <>
            <p>At Appbay, we adopt an ‘Empathy First, Technology Next’ approach and work with you every step of the way from identifying the challenges, chalking the requirements to delivering applications built to drive maximum value to your business goals.</p><br/>
            <ul>
                <li><span>&#8226;  Use global delivery model for application development&nbsp;</span></li>
                <li><span>&#8226;  Knowledge acquisition and retention&nbsp;</span></li>
                <li><span>&#8226;  Use our matrix organization structure for using the required expertise&nbsp;</span></li>
                <li><span>&#8226;  Track the SLAs norms using web tools which are transparent even to our customers</span></li>
                <li><span>&#8226;  Focus on re-usability thus reducing cycle times and costs</span></li>
                <li><span>&#8226;  Offer nearshore, offshore, and onsite delivery models</span></li>
                <li><span>&#8226;  Gain 100% referenceable clients</span></li>
                <li><span>&#8226;  Achieve super-fast implementation with pre-built solutions, suitable for clients’ needs</span></li>
            </ul>
        </>,
        imgSrc: "https://images.pexels.com/photos/8204398/pexels-photo-8204398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        render: false
    },
    Section4: {
        heading: "",
        cards: [
            {
                imageSrc: <MemoryIcon />,
                title: "Faster production time",
                description: "",

            },
            {
                imageSrc: <TrackChangesIcon />,
                title: "Enhanced accuracy",
                description: "",

            },
            {
                imageSrc: <OpenInFullIcon />,
                title: "Complete quality and reliability",
                description: "",

            },
            {
                imageSrc: <AttachMoneyIcon />,
                title: "Ensure error-free apps",
                description: "",

            },
            {
                imageSrc: <AttachMoneyIcon />,
                title: "Boost efficiency and performance",
                description: "",

            },
        ],
        render: false
    },
    Section5: {
        heading: "THE APPBAY FACTOR",
        subheading: "",
        description: "At Appbay, we strive to understand your business goals, and the importance of maintaining honesty, transparency, and adding value throughout the engagement. Our services are Power by the Passionate People who are a 100% customer success rate which stands testimony to the work we do. We are Quality Engineering and assurance specialists which ensures you get maximum value, zero errors and superior results!",
        imgSrc: logo,
        render: true
    },
}
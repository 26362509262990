import React, { useEffect, useState } from "react";
import "./Hero.css";
import TypeAnimation from "react-type-animation";
import tw from "twin.macro";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Next from "images/ForwardGIF.gif";

const Heading = styled.h1`
  ${tw` text-4xl text-center lg:text-left font-bold text-gray-100 leading-none `}
  span {
    ${tw`inline-block`}
  }
`;
const CardHeading = styled.h1`
  ${tw` text-5xl text-center font-bold text-gray-100 leading-none `}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 lg:px-4 lg:py-4 lg:text-4xl text-xl p-3`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-white transform -skew-x-12 -z-10`}
  }
`;
const PrimaryAction = tw.button`px-10 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:py-4 bg-gray-100 text-primary-500 
font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

export default function Hero() {
  let history = useHistory();

  const cards = [
    {
      title: "BPM",
      description:
        "Business process management is the discipline in which people use various methods to discover, model, analyze, measure, improve, optimize, and automate business processes.",
      url: "services/bpm",
    },
    {
      title: "CRM",
      description:
        "Customer relationship management is a process in which a business or other organization administers its interactions with customers, typically using data analysis to study large amounts of information.",
      url: "services/crm",
    },
    {
      title: "Training",
      description:
        "Training and development involves improving the effectiveness of organizations and the individuals and teams within them.",
      url: "https://training.appbaytech.com",
    },
  ];

  return (
    <>
      <div className="outer-wrapper">
        <div className="home-wrapper">
          <div className="slide1 one">
            <section>
              <div className="navbar container"></div>
            </section>
            <section
              id="media"
              style={{
                marginTop: window.innerHeight / 3,
                marginLeft: window.innerWidth / 50,
              }}
            >
              <Heading tw="pb-2">
                Accelerating
                <br />
              </Heading>
              <Heading tw="mt-2">
                <SlantedBackground
                  style={{ textAlign: "center", backgroundColor: "white" }}
                >
                  <TypeAnimation
                    cursor={false}
                    sequence={[
                      "Automation in Business Processes",
                      2000,
                      "Digitalization",
                      2000,
                      "Innovative transformation ",
                      2000,
                      "Idea to reality",
                      2000,
                      "Getting your dream job",
                      2000,
                    ]}
                    wrapper="div"
                    repeat={1000}
                    cursor="true"
                    className="typingText"
                  />
                </SlantedBackground>
              </Heading>
              <PrimaryAction
                onClick={() => {
                  history.push("/industries/Insurance");
                }}
              >
                Read Use Cases
              </PrimaryAction>
            </section>
          </div>
          <img
            src={Next}
            style={{
              position: "absolute",
              zIndex: 1000,
              height: 100,
              top: window.innerHeight / 2.5,
              right: window.innerWidth - 10,
            }}
          />
          <div className="slide2 two">
            <div className="myrow">
              {cards.map((card, index) => {
                return (
                  <a href={card.url} key={index}>
                    <div className={"container12 " + `bg-img${index + 1}`}>
                      <div className="cardyo">
                        <div className="contentBx">
                          <CardHeading>{card.title}</CardHeading>
                          <div className="size">
                            <p
                              tw="text-gray-100 mt-3"
                              style={{ textAlign: "justify" }}
                            >
                              {card.description}
                            </p>
                          </div>
                          {/* <div className="size">
                                                            <a href={card.url} style={{
                                                                color: '#F6C453',
                                                                border: '1px solid #F6C453',
                                                                padding: '7px',
                                                            }} tw="mt-3 font-bold text-lg text-primary-500"> EXPLORE</a>
                                                        </div> */}
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import { Accordion } from "react-bootstrap";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import logo from "../../images/logo.svg";

// const Container = tw(ContainerBase)`bg-primary-100 text-gray-900 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-2 py-10  sm:py-20 flex flex-col max-w-screen-xl`;

const Container = styled.div`
  ${tw`
 w-full flex flex-col items-center
`}

  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.pexels.com/photos/3285203/pexels-photo-3285203.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
`;

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;

const Subheading = tw(SubheadingBase)`mb-4 text-gray-100 `;
const Heading = tw(SectionHeading)`w-full text-gray-100`;
const Description = tw(SectionDescription)`w-full text-gray-100 text-center`;

const PlansContainer = tw.div`mt-10 flex flex-col  lg:flex-row  lg:justify-between text-gray-900 font-medium items-start`;
const Plan = styled.div`
  ${tw`w-full max-w-lg bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-auto lg:mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw` mr-3 text-3xl font-bold`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-2 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm text-justify`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded w-full px-3`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/3  bg-white z-0`;

const formatText = (symbol, text) => {
  return (
    <div tw="flex flex-row justify-start">
      <span tw="text-primary-500">{symbol}</span>
      <span tw="ml-1">{text}</span>
    </div>
  );
};

export const NavLink = tw.a`
text-lg my-2 lg:text-sm lg:mx-5 lg:my-0
font-semibold transition duration-300
pb-1 border-b-2 border-transparent hocus:text-primary-500
text-gray-100
`;

export const PrimaryLink = tw(NavLink)`
px-3 py-2 rounded bg-primary-500 text-gray-900
hocus:bg-primary-700 hocus:text-gray-700 focus:shadow-outline  items-center self-center text-center
border-b-0 
`;

export default ({
  subheading = "",
  heading = "The Skill Upgrade Partner you can trust",
  description = "Face the future with confidence and with the skills and tools to thrive",
  plans = null,
  primaryButtonText = "Register Now!",
  form,
}) => {
  const defaultPlans = [
    {
      name: "Individual",
      price: ["$9", ".99/month"],
      oldPrice: "$11.99",
      description:
        "Perfect for when you want to work as a freelancer, find your dream job or working on a hobby side project.",
      features: [
        {
          key: 0,
          title: "Job Seekers",
          description: (
            <p tw="text-justify">
              {formatText(
                <ChevronRightIcon />,
                "Get Job in your dream company in less than 3 weeks #AppbayPromise"
              )}
              {formatText(
                <ChevronRightIcon />,
                "Automation, ML, AI - Upscale yourself in the trending tech stack you want."
              )}
              {formatText(
                <ChevronRightIcon />,
                "Team of leading industry experts for training."
              )}
              {formatText(
                <ChevronRightIcon />,
                "No prior coding experience needed."
              )}
              {formatText(
                <ChevronRightIcon />,
                "Tech or Non-Tech background does not matter, we need your learning aptitude. Training on live projects with Lab setup."
              )}
            </p>
          ),
        },
        {
          key: 1,
          title: "Become a Freelancer",
          description: (
            <p tw="text-left">
              {formatText(
                <ChevronRightIcon />,
                "Get Started with your Freelancing journey in less than 3 weeks #AppbayPromise in the following :"
              )}
              <div tw="ml-5">
                {formatText(
                  <ArrowRightIcon />,
                  "Game UI design (ReactJS + Material UI)"
                )}
                {formatText(
                  <ArrowRightIcon />,
                  "Graphic Designing: Logo design, Banner design and much more"
                )}
                {formatText(<ArrowRightIcon />, "Web design (React JS)")}
                {formatText(
                  <ArrowRightIcon />,
                  "UI design: Mockup design and much more."
                )}
              </div>
              {formatText(
                <ChevronRightIcon />,
                "Get acquainted with tools like Photoshop, Illustrator, Figma etc"
              )}
              {formatText(
                <ChevronRightIcon />,
                "Training on live projects with Lab setup."
              )}
              {formatText(
                <ChevronRightIcon />,
                " Appbay hacks to attract clients. Freelancer community access."
              )}
            </p>
          ),
        },
      ],
      url: "https://google.com",
    },
    {
      name: "Startup",
      price: ["$15", ".99/month"],
      oldPrice: "$19.99",
      description:
        "Perfect for upskilling your employees/interns in your tech domain",
      features: [
        {
          key: 2,
          title: "Design labs training",
          description: (
            <p tw="text-left">
              {formatText(
                <ChevronRightIcon />,
                "Jo dikhta hai wo bikta hai , Lab tools to help you sell"
              )}
              <div tw="ml-5">
                {formatText(
                  <ArrowRightIcon />,
                  "Graphic Design for creating presentations, Pitch decks"
                )}
                {formatText(
                  <ArrowRightIcon />,
                  "Design anything: UI design principals and graphic library hacks"
                )}
                {formatText(
                  <ArrowRightIcon />,
                  " Get acquainted with tools like Illustrator"
                )}
              </div>
              {formatText(
                <ChevronRightIcon />,
                "Upscale your team UI designing capabilities Learn to create design wing for your company"
              )}
            </p>
          ),
        },
        {
          key: 3,
          title: "Upskilling with tech stacks",
          description: (
            <p tw="text-left">
              {formatText(
                <ChevronRightIcon />,
                "Upskill your team technical skills with the tech stacks you want"
              )}
              {formatText(
                <ChevronRightIcon />,
                "Team of leading industry experts for training."
              )}
              {formatText(
                <ChevronRightIcon />,
                "Customized training batches availability"
              )}
            </p>
          ),
        },
      ],
      url: "https://google.com",
      featured: "",
    },
    {
      name: "Corporate",
      description:
        "Perfect for upskilling your team members with latest technologies and practices",
      features: [
        {
          key: 4,
          title: "Design labs training",
          description: (
            <p tw="text-left">
              {formatText(
                <ChevronRightIcon />,
                "Lab tools to help you setup your design wing"
              )}
              <div tw="ml-5">
                {formatText(
                  <ArrowRightIcon />,
                  "Graphic Design for creating presentations, project proposals, pre-sales and sales deck and much more."
                )}
                {formatText(
                  <ArrowRightIcon />,
                  "Get your team acquainted with tools : Illustrator, Photoshop, Vyond"
                )}
              </div>
              {formatText(
                <ChevronRightIcon />,
                "Upscale your team UI designing capabilities"
              )}
            </p>
          ),
        },
        {
          key: 5,
          title: "Upskilling with tech stacks",
          description: (
            <p tw="text-justify">
              {formatText(
                <ChevronRightIcon />,
                "Upskill your team technical skills with the tech stacks you want."
              )}
              {formatText(
                <ChevronRightIcon />,
                "Team of leading industry experts for training."
              )}
              {formatText(
                <ChevronRightIcon />,
                "Customized training batches availability"
              )}
            </p>
          ),
        },
      ],
      url: "https://google.com",
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <div tw="flex flex-row justify-between w-full p-5">
        <img
          src={logo}
          width="120"
          height="120"
          alt="React Bootstrap logo"
          onClick={() => window.open("https://www.appbaytech.com")}
        />
        <PrimaryLink href="https://www.appbaytech.com" target="_blank">
          Go to Appbay
        </PrimaryLink>
      </div>

      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, pindex) => (
            <Plan>
              <PlanHeader key={pindex}>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                  {plan.featured && (
                    <span className="featuredText">{plan.featured}</span>
                  )}
                </span>
                <p className="description" style={{ maxHeight: 40 }}>
                  {plan.description}
                </p>
              </PlanHeader>
              <PlanFeatures>
                <Accordion>
                  {plan.features.map((feature, index) => {
                    return (
                      <Accordion.Item eventKey={feature.key}>
                        <Accordion.Header
                          style={{ backgroundColor: "#fff", color: "#F6C453" }}
                        >
                          <span tw="text-gray-900 font-bold">
                            {feature.title}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body tw="text-sm">
                          {feature.description}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </PlanFeatures>
              <PlanAction>
                <ActionButton onClick={form}>{primaryButtonText}</ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      {/* <WhiteBackgroundOverlay /> */}
    </Container>
  );
};

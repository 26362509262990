import React from "react";
import tw from "twin.macro";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import Startup from "images/Startup.svg";
import Enterprise from "images/Enterprise.svg";
import SME from "images/SME.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import BusinessIcon from "@mui/icons-material/Business";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto lg:py-10 md:py-24`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative px-10`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 lg:mt-0 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-0 md:order-first`
    : tw`md:ml-12 lg:ml-0 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-80`,
]);
const TextContent = tw.div`lg:py-4 text-center md:text-left`;

const Heading = tw(SectionHeading)`w-full lg:mt-8 py-4`;
const Description = styled.p`
  ${tw`mt-4 text-justify lg:mr-10 text-sm font-medium text-secondary-100 px-5`}
  -webkit-transition: all 1s 0s ease;
`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`my-10 px-10 text-sm inline-block mx-auto md:mx-0 hocus:text-gray-700 text-gray-900`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const ButtonContainer = styled.div`
  ${tw`relative lg:mx-auto flex lg:flex-col justify-between lg:px-0 px-10   text-center order-first lg:mr-20 mt-0`}
`;

const ButtonText = tw.p`mt-2 text-center text-sm font-bold  text-black mb-5`;
const AnimatedButton = styled.button`
  height: 70px;
  width: 70px;
  animation: morph 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  transition: all 1s ease-in-out;
  z-index: 5;

  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
      border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
  }
`;

const CircularButton = styled(AnimatedButton)((props) => [
  props.active
    ? tw`relative mx-auto flex lg:flex-col justify-center  items-center text-center text-white bg-primary-500 font-bold`
    : tw`relative mx-auto flex lg:flex-col justify-center  items-center text-center text-white bg-secondary-100 font-bold`,
]);

const formatText = (text) => {
  return (
    <div tw="flex flex-row justify-start">
      <span tw="text-primary-500">
        <ChevronRightIcon />
      </span>
      <span tw="ml-1">{text}</span>
    </div>
  );
};

export default ({
  subheading = "Our Track Record",
  heading = (
    <>
      We have been doing this <wbr /> since{" "}
      <span tw="text-primary-500">1999.</span>
    </>
  ),
  primaryButtonText = "Consult Us",
  primaryButtonUrl = "/contact-us/consultation",

  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = true,
  imageDecoratorBlobCss = null,
  imageInsideDiv = false,
  statistics = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const description = [
    {
      imageSrc: Startup,
      text: (
        <Description>
          {formatText(
            "The feasibility study, Discovery Phase, and proof of concept phase will validate your idea to help you raise funding"
          )}
          {formatText(
            "MVP development within 3-5 weeks: go-to-market quicker to receive end-users feedback."
          )}
          {formatText(
            "Resource augumentation and dedicate development team to boost the performance. Extend and strengthen your team with additional resources"
          )}
        </Description>
      ),
    },
    {
      imageSrc: Enterprise,
      text: (
        <Description>
          {formatText(
            <>
              <strong>Outcome Based Services : </strong>Solving business problem
              by providing innovative solutions that has high ROI and is
              inclusive of all the Application Design and Implementation and
              support services, Center of Excellence and Quality Assurance.
            </>
          )}
          {formatText(
            <>
              <strong>Talent acquisition :</strong> Exprienced HR team to help
              solve demand vs supply resourcing problem.
            </>
          )}
          {formatText(
            <>
              <strong>
                Resource Augmentation and Dedicated development team:
              </strong>{" "}
              Scaling up your development capacity and adding needed tech skills
              to your team.
            </>
          )}
        </Description>
      ),
    },
    {
      imageSrc: SME,
      text: (
        <Description>
          {formatText(
            <>
              <strong>Individual :</strong> Get trained on courses designed to
              make candidate certification ready. Flexible Schedule as the
              individuals availability. Guidance and mocks given for
              certifications with practice sessions from time to time. One to
              one problem solving sessions with the trainer
            </>
          )}
          {formatText(
            <>
              <strong>Startup :</strong> Upskill your employees/interns in your
              tech domain. Guidance from industry experts
            </>
          )}
          {formatText(
            <>
              <strong>Corporate :</strong> Get trained on courses designed as
              per latest market requirements. Virtual and classroom trainings as
              per requirements. Training designed to make candidates project
              ready in limited span.
            </>
          )}
        </Description>
      ),
    },
  ];

  const [active, setActive] = React.useState(0);

  const buttons = [
    // {
    //   title: "Startups",
    //   icon: <RocketLaunchIcon style={{ fontSize: 30 }} />,
    // },
    {
      title: "Enterprises",
      icon: <BusinessIcon style={{ fontSize: 30 }} />,
    },
    {
      title: "Training",
      icon: <ModelTrainingIcon style={{ fontSize: 30 }} />,
    },
  ];

  const handleChange = (index) => {
    console.log(index);
    setActive(index);
  };

  return (
    <Container>
      <Heading>
        How we can help{" "}
        <span tw="text-primary-500 font-black">Your Business ?</span>
      </Heading>

      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ButtonContainer>
          {buttons.map((b, i) => {
            return (
              <div>
                <CircularButton
                  onClick={() => handleChange(i)}
                  active={i == active}
                >
                  {b.icon}
                </CircularButton>
                <ButtonText>{b.title}</ButtonText>
              </div>
            );
          })}
        </ButtonContainer>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={description[active].imageSrc} css={imageCss} />
          ) : (
            <img
              src={description[active].imageSrc}
              css={imageCss}
              alt={buttons[active].title}
            />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingBase>{buttons[active].title}</SubheadingBase>
            <Description>{description[active].text}</Description>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SavingsIcon from '@mui/icons-material/Savings';
import GavelIcon from '@mui/icons-material/Gavel';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Link,useParams
} from "react-router-dom";

const HeadingContainer = tw.div`py-0`
const Heading = tw(SectionHeading)`mt-10`
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto hover:cursor-pointer`
const Card = tw.div`mt-10 w-full sm:w-1/2 lg:w-1/6 flex flex-col items-center hover:text-primary-500`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-32 h-32 bg-contain bg-center rounded hover:text-primary-500`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-2`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-2 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hover:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6 hover:text-primary-500`}
    }
  }
`

export default ({
  heading = "Read our Case Studies",
  subheading = "",
  description = "",
  cards = [
    {
      imageSrc: <LocalHospitalIcon />,
      link: "Insurance",
      name: "Insurance",
    },
    {
      imageSrc: <GavelIcon />,
      link: "Governance",
      name: "Governance",
    },
    {
      imageSrc: <LocalPhoneIcon/>,
      link: "Telecom",
      name: "Telecom",
    },
    {
      imageSrc: <AddModeratorIcon />,
      link: "HealthCare",
      name: "HealthCare",
    },
    {
      imageSrc: <TranslateIcon />,
      link: "Translation",
      name: "Translation",
    },
    {
      imageSrc: <SavingsIcon />,
      link: "Banking",
      name: "Banking",
    },
  ]
}) => {
  let { contentRef } = useParams();
  console.log(contentRef)
  return (
    <Container>
      <ContentWithPaddingXl tw="py-0 mt-5">
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card component={Link} to={card.link} key={index}>
              <a href={card.link} tw="items-center mx-auto flex flex-col hover:text-primary-500"
              style={{
                textDecoration: contentRef === card.name?"underline":null,
                color: contentRef === card.name?'#F6C453':null
                }}
              >
                <div tw="hover:text-primary-500">{card.imageSrc}</div>
                <CardContent>
                  <span className="name" 
                  style={{
                    textDecoration: contentRef === card.name?"underline":null,
                    color: contentRef === card.name?'#F6C453':null
                    }}>
                    {card.name}</span>
                  {/* <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks> */}
                </CardContent>
              </a>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TypeAnimation from 'react-type-animation';
import {
  Link, useHistory
} from "react-router-dom";

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 mx-5`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-secondary-900 opacity-50`;

const HeroContainer = tw.div`z-20 relative sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`flex flex-col items-center lg:block`;

const Service = styled.div`
${tw`relative h-full`}
`;

const Heading = styled.h1`
  ${tw`lg:text-3xl text-2xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 lg:px-4 lg:py-2 lg:text-6xl text-2xl p-3`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-10 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const Mobile = window.innerWidth <= 768
  let history = useHistory()
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <TwoColumn>
          <LeftColumn>
            {/* <Notification>We have now launched operations in Europe.</Notification> */}
            <Heading>
              Accelerating
              <br />
            </Heading>
            <Heading>
              <SlantedBackground
                style={{ textAlign: "center" }}
              >
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    'Automation in Business Processes', 2000,
                    'Digitalization', 2000,
                    'Innovative transformation ', 2000,
                    'Idea to reality', 2000,
                    'Getting your dream job', 2000,
                  ]}
                  wrapper="div"
                  repeat={1000}
                  cursor="true"
                  className="typingText"
                />
              </SlantedBackground>
            </Heading>
            <PrimaryAction onClick={() => { history.push("/industries/Insurance"); }}>Read Use Cases</PrimaryAction>
          </LeftColumn>
          {/* <RightColumn>

          </RightColumn> */}
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

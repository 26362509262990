import logo from '../../images/logo-icon.svg'
import MemoryIcon from '@mui/icons-material/Memory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ConsultationTabs from './ConsultationTabs';
import Consultation1 from 'images/Consultation/1.svg'
import Consultation2 from 'images/Consultation/2.svg'
import Consultation3 from 'images/Consultation/3.svg'

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MainFeature3 from "components/features/SixColSimple";

export const consultation = {
    Section1: {
        heading: "Consultation",
        subheading: "Get started on the right track with expert business process consulting",
        description: "Appbay, offers a range of business process consulting that not only helps you to boost your productivity and reduce cost but also builds an understanding about how well business processes are used. We can help you to re-engineer your operational practices with the latest technology and align them with the best practices if outsourcing is not feasible .",
        imgSrc: Consultation1,
        render: true,
        showButton: true,
        primaryButtonText: "Request Demo",
        primaryButtonUrl: "/contact-us/consultation"
    },
    Section2: {
        heading: "WHY DO YOU NEED OUR CONSULTING SERVICES?",
        subheading: "",
        description: <>
            <p>Starting and managing a new project or deciding on a software platform is never easy – and the difference between success and failure can often come down to the little details. If you’re looking for help with your project, there’s no better way to get the answers you need than consulting with an industry professional. Our consultants can help you choose an effective platform or support your business idea from inception to execution. Let our experts help take your project from good to great, before things start falling apart.</p>
            <br />

        </>,
        imgSrc: Consultation2,
        render: true,
        extraContent: <>
            <MainFeature3
                heading=""
                cards={[
                    {
                      imageSrc: <AutoGraphIcon/>,
                      title: "Continuous improvement",
                      description: "",
                      
                    },
                    {
                      imageSrc: <BubbleChartIcon/>,
                      title: "Enhanced transparency",
                      description: "",
                      
                    },
                    {
                      imageSrc: <StackedBarChartIcon/>,
                      title: "Improve future processes",
                      description: "",
                      
                    },
                    {
                      imageSrc: <ShowChartIcon/>,
                      title: "Increasing revenue without increasing headcount",
                      description: "",
                      
                    },
                  ]}
            />
            
        </>

    },
    Section3: {
        heading: "WHAT WE DO?",
        subheading: "",
        description: <>
            <p>It is important to remember that businesses operate in a risk-filled world. As part of your preparation, you need to identify potential risks and you need a plan of action for solving them.That’s why you’re here—so we can help you identify those risks and find elegant solutions that keep your business running smoothly. Let us make sure you’ve covered all the right bases.</p>

        </>,
        imgSrc: Consultation3,
        render: true,
        tabs: true
    },
    Section4: {
        heading: "",
        cards: [
            {
                imageSrc: <MemoryIcon />,
                title: "Understand Business Needs",
                description: "",

            },
            {
                imageSrc: <TrackChangesIcon />,
                title: "Understand the business Pain Points",
                description: "",

            },
            {
                imageSrc: <OpenInFullIcon />,
                title: "Evaluate the Current Infrastructure(either Software or IT)",
                description: "",

            },
            {
                imageSrc: <AttachMoneyIcon />,
                title: "Recommending New Solutions",
                description: "",

            },
        ],
        render: false
    },
    Section5: {
        heading: "THE APPBAY FACTOR",
        subheading: "",
        description: "Shouldn’t every business have a plan? If you need help developing one, we at Appbay help you create an actionable plan that will help you stay on track with your goals. As your partner, we bring a 360 Degree view of industry and competencies to the table. We do not believe in consulting you based on any predetermined framework, but rather, we try and solve problems using the industry best practices combined with our domain expertise.",
        imgSrc: logo,
        render: true
    },
}
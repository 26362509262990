import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import GavelIcon from '@mui/icons-material/Gavel';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import BookIcon from '@mui/icons-material/Book';
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)`w-full lg:pt-4 pt-8`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`lg:mt-10 my-5 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 lg:py-8 py-5 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 hover:text-primary-500 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-100`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const HighlightedText = tw.span`bg-primary-500 font-black text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({
  cards = [
    {
      icon: <GavelIcon />,
      title: "Our Philosphy",
      description: "Have a decisive influence on the corporate strategy by helping to achieve the Vision and Mission",
      url: "/about-us"
    },
    {
      icon: <BookIcon />,
      title: "Our Values",
      description: "Loyalty, Honesty and Trust",
      url: "/about-us"
    },
    // {
    //   icon: <JoinFullIcon />,
    //   title: "Join Us",
    //   description: "Are you a team member?",
    //   url: "/career"
    // }
  ],
  linkText = "",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = tw`p-2!`,
  imageCss = tw`w-20! h-20!`
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container tw="mb-10">

      {subheading && <Subheading>{subheading}</Subheading>}
      <Heading>The  <span tw="text-primary-500 font-black">Appbay Stack</span></Heading>
      {description && <Description>{description}</Description>}
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card href={card.url}>
              <span className="imageContainer" css={imageContainerCss}>
                {card.icon}
              </span>
              <span className="title">{card.title}</span>
              <p className="description">{card.description}</p>
              {linkText && (
                <span className="link">
                  <span>{linkText}</span>
                  <ArrowRightIcon className="icon" />
                </span>
              )}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};

import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import React, { Component } from "react";
import Timetable from "components/table/timetable";
import SpeedDialForm from "components/forms/SpeedDialForm";
import ValueFeatures from "components/features/DashedBorderSixFeatures";
import JobCard from "components/cards/ThreeCardSlider";
import MultiImageSlider from "components/carasoual/MultiImageSlider";
import BarChartIcon from "@mui/icons-material/BarChart";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PeopleIcon from "@mui/icons-material/People";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

import Footer from "components/footers/MiniCenteredFooter";
import { withRouter } from "react-router-dom";
import CompanySlider from "components/carasoual/companySlider";
import CheckBoxDemo from "components/forms/checkboxDemo";

class Training extends Component {
  state = {
    formOpen: false,
    imgStyle: {
      height: 40,
      width: 40,
    },
  };
  setFormState = () => {
    this.setState({
      formOpen: !this.state.formOpen,
    });
    console.log(this.state.formOpen);
  };
  render() {
    return (
      <div>
        <Pricing form={this.setFormState} />
        <CheckBoxDemo />
        <MultiImageSlider form={this.setFormState} />
        <Timetable />
        <ValueFeatures
          heading={
            <>
              Job Assistance{" "}
              <span tw="text-primary-500 font-black">Program</span>
            </>
          }
          cards={[
            {
              imageSrc: <BarChartIcon style={this.state.imgStyle} />,
              title: "Career Counseling Sessions",
              description:
                "Once you know the skills, we recommended you understand the eligible job roles, scope, and opportunities.",
            },
            {
              imageSrc: <FactCheckIcon style={this.state.imgStyle} />,
              title: "Custom Resume Builder Access",
              description:
                "We got a resume builder to help you prepare the best resume to win the first impression for recruiters.",
            },
            // {
            //   imageSrc: <CameraFrontIcon style={this.state.imgStyle} />,
            //   title: "Life-Time Self-Paced Videos Access",
            //   description: "Applicable for Live-Online learning mode. Provides a set of self-paced videos to recap the subject whenever/wherever you need."
            // },
            {
              imageSrc: <ShowChartIcon style={this.state.imgStyle} />,
              title: "Mock Interviews",
              description:
                "Explore what the real-time interviews expect from you.",
            },
            {
              imageSrc: <PeopleIcon style={this.state.imgStyle} />,
              title: "Privileged Community Support",
              description:
                "We make sure to address any real-time challenges our alumni face during their careers through our community platform.",
            },
            {
              imageSrc: <EmojiObjectsIcon style={this.state.imgStyle} />,
              title: "Quizzes To Scale",
              description:
                "Get access to our Quiz from the LMS to scale your knowledge.",
            },
          ]}
        />
        {/* <JobCard form={this.setFormState} /> */}
        <SpeedDialForm open={this.state.formOpen} form={this.setFormState} />
        <CompanySlider />
        <Footer />
      </div>
    );
  }
}

export default withRouter(Training);

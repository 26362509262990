import pdf from '../../pdf/Appbay - Case Study For Telecom Domain.pdf'
import Telecom1 from 'images/Telecom.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Telecom = {

    heading: "Telecom",
    subheading: "",
    description: <>
    <strong>Client : </strong>Telecom customer<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Complex architecture and un-synced legacy systems to handle data for millions of end-users, thereby resulting into huge number of man-hours to handle end-user basic operations. </p>
    <p><ChevronRightIcon/> Compromised PPI data of customers. </p>
    <p><ChevronRightIcon/> Poor operational efficiency resulting into poor end-user experience. </p>
    <br/>
    <strong>Solution : </strong>Appbay helped to create a Salesforce-driven solution that's made it easy to create Account and contacts without duplicity using salesforce out of the box features like exact and fuzzy matching rules. Further with help of permission sets and profiles, sensitive data was restricted for concerned customers. Using Salesforce inbuilt cache mechanism fast retrieval of customer data was achieved which earlier took huge amount of time
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> 5x reduction in manual effort due to reduced end-user complaints. </p>
    <p><ChevronRightIcon/> PPI issues eliminated </p>
    <p><ChevronRightIcon/> End to end automated business processes for such huge volume of data helped to grow high agility and productivity eliminating earlier silos and pen - paper processes. </p>
    </>,
    imgSrc: Telecom1,
    pdf: pdf

}
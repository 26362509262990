import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-900 text-gray-100  -mx-8 px-8 py-5 lg:py-10`;
const Content = tw.div` lg:mx-10 relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/6 md:w-auto mt-10`;

const ColumnHeading = tw.h5`uppercase font-bold text-primary-500 text-sm text-left`;

const LinkList = tw.ul`mt-6 text-xs font-medium`;
const LinkListItem = tw.li` text-justify`;
const Link = tw.a`border-b-2 border-transparent hover:text-primary-500 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(
  Column
)`text-center lg:text-left w-full! lg:w-auto! mt-5 lg:mt-10`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.a`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(
  PrimaryButtonBase
)`mt-4 sm:mt-0 w-80 sm:w-auto rounded sm:rounded lg:px-10 px-5 py-3`;

const Divider = tw.div`my-5 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start pb-10`;
const LogoImg = tw.img`w-64 lg:mr-auto sm:mx-auto `;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base lg:mt-8 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`lg:mt-8 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const logoText = (
  <p tw="text-justify">
    Appbay technologies is an IT service and consultancy firm that focuses on
    giving tailored solutions leveraging the power of low-code platforms like
    Appian BPM, Outsystems, Salesforce and other web based technologies
  </p>
);

const Mobile = window.innerWidth < 768;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <div style={{ width: Mobile ? "100%" : "30%" }} tw="mx-3 mt-5">
            <LogoContainer>
              <LinkList>
                <LinkListItem>
                  <LogoImg src={LogoImage} alt="Logo" />
                  <br />
                </LinkListItem>
                <LinkListItem>{logoText}</LinkListItem>
              </LinkList>
              {/* <LogoText>AppbayTech</LogoText> */}
            </LogoContainer>
          </div>
          <Column style={{ width: Mobile ? "50%" : "" }}>
            <ColumnHeading>Services by capabilities</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/services/bpm"> BPM</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/services/crm"> CRM</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://training.appbaytech.com">Training</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column style={{ width: Mobile ? "50%" : "" }}>
            <ColumnHeading>Services by business needs</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/services/Consultation">
                  {" "}
                  Consultation/Advisory
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/services/Development">
                  {" "}
                  Development, Implementation
                  <br /> and Support
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/services/Quality"> Quality Assurance</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/services/Excellence"> Center of Excellence</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Industries</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/industries/Banking">Banking</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/industries/Insurance">Insurance</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/industries/Governance">Governance</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/industries/Telecom">Telecom</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/industries/HealthCare">Health care</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/industries/Translation">Translation</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          {/* <Column>
            <ColumnHeading>Company</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/about-us">Our philosphy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/about-us">Need Help?</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/career">Careers</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}

          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              {/* <ColumnHeading>Connect with us</ColumnHeading> */}
              <SubscribeText></SubscribeText>
              <SubscribeForm href="/contact-us/connect">
                {/* <Input type="email" placeholder="Your Email Address" /> */}
                <SubscribeButton>Connect with Us</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <CopywrightNotice>
            &copy; 2021 AppbayTech All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            {/* <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
            {/* <SocialLink href="https://youtube.com">
              <LinkedinIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};

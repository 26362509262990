import pdf from '../../pdf/Appbay - Case Study For Translation Domain.pdf'
import Translation1 from 'images/Translation.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Translation = {

    heading: "Translation",
    subheading: "",
    description: <>
    <strong>Client : </strong>Translation customer<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Increasing and never-ending volumes of translation data and limited budget. </p>
    <p><ChevronRightIcon/> Reducing the time it takes to translate from English into local languages. </p>
    <p><ChevronRightIcon/> Translate high volumes of content, while simultaneously reducing the publishing time between English and local language websites. </p>
    <p><ChevronRightIcon/> Reduce Inconsistent experiences on local websites. </p>
    <br/>
    <strong>Solution : </strong> Application enabled the project management offices & the language offices of client to better manage & automate the translation & delivery process end-to-end.
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> Approximately 20,000 translation jobs are being created each month in application by the users who are based in different parts of world to translate 50 million + words from over 286 different language flavours </p>
    <p><ChevronRightIcon/> It has helped them manage complex business scenarios with ease and improve the end-user experience and productivity. </p>
    <p><ChevronRightIcon/> Reduces human errors </p>
    </>,
    imgSrc: Translation1,
    pdf: pdf

}
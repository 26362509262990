import "tailwindcss/dist/base.css";
import React from "react";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

import AgencyLandingPage from "demos/AgencyLandingPage.js";
import CareerPage from "pages/Career.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import CaseStudy from "pages/CaseStudy.js";
import ServicesPage from "pages/Services";
import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Training from "pages/Training";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Capability from "pages/Capability";

const firebaseConfig = {
  apiKey: "AIzaSyAH012RAO7UkWOXu-KOVOHlTdoRVBfcqSI",
  authDomain: "appbaytech-2ef74.firebaseapp.com",
  projectId: "appbaytech-2ef74",
  storageBucket: "appbaytech-2ef74.appspot.com",
  messagingSenderId: "461879957137",
  appId: "1:461879957137:web:85d23da17a08f6e9de9130",
  measurementId: "G-3N5XPFD5VG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const websiteLive = true;

  if (window.location.host.split(".")[0] == "training") {
    return (
      <Router>
        <Training />
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {websiteLive ? <AgencyLandingPage /> : <ThankYouPage />}
        </Route>

        <Route exact path="/services/:contentRef">
          {websiteLive ? <ServicesPage /> : <ThankYouPage />}
        </Route>
        <Route exact path="/industries/:contentRef">
          {websiteLive ? <CaseStudy /> : <ThankYouPage />}
        </Route>
        <Route exact path="/about-us">
          {websiteLive ? <AboutUsPage /> : <ThankYouPage />}
        </Route>
        <Route exact path="/contact-us/:type">
          {websiteLive ? <ContactUsPage /> : <ThankYouPage />}
        </Route>
        {/* <Route exact path="/career">
          {websiteLive ? <CareerPage /> : <ThankYouPage />}
        </Route> */}
        <Route exact path="/capabilityoverview">
          <Capability />
        </Route>
      </Switch>
    </Router>
  );
}

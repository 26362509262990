import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SingleCol.js";
import Hero from "components/hero/FullWidthWithImage";
import JoinUsForm from "components/forms/JoinUsForm";


function Hidden(){
  return(
      <div style={{
          backgroundColor: 'white',
          height: 50,
          width: window.innerWidth,
          bottom: 50,
          position:'relative'
      }}></div>
  )
} 

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Hero/>
      <JoinUsForm/>
      <Hidden/>
      <Testimonial
        
      />
      {/* <FAQ /> */}
      <Footer/>
    </AnimationRevealPage>
  );
};
 
import logo from '../../images/logo-icon.svg'
import MemoryIcon from '@mui/icons-material/Memory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import InsightsIcon from '@mui/icons-material/Insights';
import SimpleSlider from 'components/carasoual/slider';

export const training = {
    Section1:{
        heading : "CORPORATE /INDIVIDUAL TRAINING",
        // subheading : "Align, Automate and Optimize Business Processes",
        description : "empower and skill up anyone for the future. ",
        // imgSrc: "https:///images.pexels.com/photos/3194518/pexels-photo-3194518.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        render: false
    },
    Section2:{
        heading : "ACCELERATING BUSINESS PROCESS MANAGEMENT PERFORMANCE AND GROWTH",
        subheading : "",
        description : "Appbay innovative business process management (BPM) solutions develop and apply the processes, rules, and workflows to transform your business-critical and non-core processes to accelerate performance, improve enterprise outcomes, and agility. Our Appian-powered BPM solutions deliver high speed of development with reduced overall system cost. We provide complete end-to-end IT services from solution strategy, consulting, design and development to implementation of BPM.",
        imgSrc: "https://images.pexels.com/photos/5273568/pexels-photo-5273568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        render: false
    },
    Section3:{
        heading : "APPIAN: DRIVING CHANGE IN LOW-CODE DEVELOPMENT",
        subheading : "",
        description : "Appian offers a revolutionary low-code development platform that empowers non-technical users to as build a powerful applications within minutes. With Appian’s seamless platform, you can create and deploy custom applications within weeks not months – so you can do more with less time and money! Vuram leverages the phenomenal capabilities of this platform to streamline workflows, build custom applications and help our clients get more done for less!",
        imgSrc: "https://appian.com/content/dam/appian-aem/appian-logo-meta-image.png",
        render: false
    },
    Section4:{
        heading : "WHY BPM?",
        cards : [
            {
              imageSrc: <MemoryIcon/>,
              title: "GO DIGITAL",
              description: "Eliminate paperwork, automate repetitive processes; digitize your business",
              
            },
            {
              imageSrc: <TrackChangesIcon/>,
              title: "BETTER ACCURACY",
              description: "Make your system learn and improve processes continuously!",
              
            },
            {
              imageSrc: <OpenInFullIcon/>,
              title: "SCALE ENDLESSLY",
              description: "It only gets better; your BPM system evolves with you every step of the way!",
              
            },
            {
              imageSrc: <AttachMoneyIcon/>,
              title: "CUT DOWN COSTS",
              description: "Better workflows and reduced human workloads equal significant savings",
              
            },
            {
              imageSrc: <TimelapseIcon/>,
              title: "IMPROVED PRODUCTIVITY",
              description: " Streamlined processes mean you invest less, get more.",
              
            },
            {
              imageSrc: <RocketLaunchIcon/>,
              title: "REDUCED TIME TO MARKET",
              description: "Make your system learn and improve processes continuously!",
              
            },
            {
              imageSrc: <GroupWorkIcon/>,
              title: "FOSTER COLLABORATIONS",
              description: "Structures and maps processes to one another to eliminate silos",
              
            },
            {
              imageSrc: <InsightsIcon/>,
              title: "MEASURE RESULTS",
              description: "Monitor end-to-end processes to continuously improve.",
              
            },
          ],
        render: false
    },
    Section5:{
        heading : "THE APPBAY FACTOR",
        subheading : "",
        description : "Appbay curriculum is largely based on a hands-on approach comprising 80% of practicals and 20% in lectures, You will also work on real-world industry-specific projects that can ensure an ideal job for you. We Provide training in all the leading technologies like appian, salesforce, Microservices, APIGEE  etc.You name it , and appbay got you covered. ",
        imgSrc: logo,
        render: true
    },
    Section6:{
      heading : "There's Nothing Better Than Getting Better.",
      

    }

}
import React, { Component } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import './ThreeColSlider.css'

const Container = tw.div `relative`;
const Content = tw.div `max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div `flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)
``;



const CardSlider = styled(Slider)`
  ${tw`mt-16 items-start`}
  .slick-track { 
    ${tw`flex `}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1 items-start`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;


const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div``;
const Title = tw.h5`text-2xl font-bold text-center`;


const Description = tw.div`text-sm leading-loose mt-2 sm:mt-4`;
const BackDescription = tw.div`text-sm leading-loose mt-2 font-medium sm:mt-4 text-left`;


const cards = [
  {
    imageSrc: "https://images.pexels.com/photos/3280130/pexels-photo-3280130.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Values",
    description: "Powered solely by values, we are a thriving example of how a business can be built.",
    backDescription: <>
      <p> &#8226; <strong>Being ethical : </strong>Two simple words. A simple idea, but a challenge to put into practice. Something we are getting better at with each passing day.</p>
    <p>&#8226; <strong>100 % Commitment :</strong> Our actions become a work of art when we think them through, feel them, and take action. </p>
    <p>&#8226; <strong>Client First :</strong>  When it comes to our clients and colleagues, we care and put their interests above our own</p>
    </>,
  },
  {
    imageSrc: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Business Model",
    description: "We are in the business of building trust, operating as one-stop shop of low-code apps and investing in our client relationships",
    backDescription: <>
    <p> &#8226; <strong>Coding Trust  : </strong> Our ability to earn the trust of our colleagues and clients is the business we are in. Trust without knowledge is innocence, not confidence; and knowledge without trust is arrogance, not competence. We strive hard to earn the trust of our colleagues and clients, while continuing to build on our experience in all areas.</p>
  <p>&#8226; <strong>Unity :</strong>As one team, we work together in support of our clients' common values and goals</p>
  <p>&#8226; <strong>Investment :</strong>  With an entrepreneurial mindset, we invest in our clients' business problems</p>
  </>
  },
  {
    imageSrc: "https://images.pexels.com/photos/212286/pexels-photo-212286.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    title: "Innovation",
    description: "Innovation is in our DNA and that allows us to challenge you to see if we can come up with an offer you just can’t resist.    ",
    backDescription: <>
    <p> &#8226; <strong>Design : </strong> Appbay is all about design. We strive to deliver customer value through innovation – all by design. Our business strategy and processes are driven by design thinking, resulting in sustainable and scalable business momentum.</p>
  <p>&#8226; <strong>Solutioning : </strong> We believe in partnering with our clients. And listening – really listening -- to what they really need. Whether it's insightful data and technology that allows them to be informed consumers. Or a digital solution that their employees love to use. We can help you discover what's possible
</p>
  <p>&#8226; <strong>Engineering :</strong> Engineering ideas to solution, we take a holistic approach to innovation that’s proven to help create data-driven design and meaningful proof of concepts. Through our ‘Design+Tech’ thought process, we deliver the tools and training you need to get innovative products from concept to launch.</p>
  </>
  },
]

const sliderSettings = {
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      }
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
};

export default class ThreeColSlider extends Component {

  state = {
    show: [false, false, false],
    sliderRef: null
  }

  flipHandler = (index, value) => {
    const temp = this.state.show
    temp[index] = value
    this.setState({
      show: temp
    })
  }

  


  render() {
    return (
      <Container>
        <Content>
          <HeadingWithControl>
            <Heading>Our <span tw="text-primary-500 font-black">Philosophy</span></Heading>
            
          </HeadingWithControl>
          <CardSlider {...sliderSettings} >
            {cards.map((card, index) => (
              <Card key={index} onMouseEnter={() => this.flipHandler(index, true)} onMouseLeave={() => this.flipHandler(index, false)} className="flip-card">
                <div className="flip-card-inner">
                  <CardImage imageSrc={card.imageSrc} />
                  {
                    !this.state.show[index]
                      ?
                      <div className="flip-card-front">
                        <TextInfo >
                          <TitleReviewContainer>
                            <Title>{card.title}</Title>
                          </TitleReviewContainer>
                          <Description>{card.description}</Description>
                        </TextInfo>
                      </div>
                      :
                      <TextInfo className="flip-card-back">
                        <TitleReviewContainer>
                        </TitleReviewContainer>
                        <BackDescription>{card.backDescription}</BackDescription>
                      </TextInfo>
                  }
                </div>
              </Card>
            ))}

          </CardSlider>
        </Content>
      </Container>
    )
  }
}
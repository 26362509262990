import logo from '../../images/logo-icon.svg'
import MemoryIcon from '@mui/icons-material/Memory';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import InsightsIcon from '@mui/icons-material/Insights';
import BPM1 from 'images/BPM/BPM1.svg'
import BPM2 from 'images/BPM/BPM2.svg'

export const bpm = {
    Section1:{
        heading : "BUSINESS PROCESS MANAGEMENT",
        subheading : "Design ,Enhance  and Automate Business Processes",
        description : "It’s a hyper-competitive era! You need to sustain and thrive by moving faster, taking initiatives and more. We can help you do that with our expert business process management service. We will help you analyze your current processes and compile your requirements in a highly innovative manner. With our years of experience and deep understanding of the business needs, we can streamline your cumbersome business processes and enable you to have a vibrant, agile and fast-paced organization.",
        // imgSrc: "https://images.pexels.com/photos/3194518/pexels-photo-3194518.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        imgSrc: BPM1,
        render: true
    },
    Section2:{
        heading : "ACCELERATING BUSINESS PROCESS MANAGEMENT PERFORMANCE AND GROWTH",
        subheading : "",
        description : "Appbay innovative business process management (BPM) solutions develop and apply the processes, rules, and workflows to transform your business-critical and non-core processes to accelerate performance, improve enterprise outcomes, and agility. Our Appian-powered BPM solutions deliver high speed of development with reduced overall system cost. We provide complete end-to-end IT services from solution strategy, consulting, design and development to implementation of BPM.",
        // imgSrc: "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        imgSrc: BPM2,
        render: true
    },
    Section3:{
        heading : "APPIAN: DRIVING CHANGE IN LOW-CODE DEVELOPMENT",
        subheading : "",
        description : "Appian offers a revolutionary low-code development platform that empowers non-technical users to as build a powerful applications within minutes. With Appian’s seamless platform, you can create and deploy custom applications within weeks not months – so you can do more with less time and money! Appbay leverages the phenomenal capabilities of this platform to streamline workflows, build custom applications and help our clients get more done for less!",
        imgSrc: "https://appian.com/content/dam/appian-aem/appian-logo-meta-image.png",
        render: true
    },
    Section4:{
        heading : "WHY BPM?",
        cards : [
            
            {
              imageSrc: <TrackChangesIcon/>,
              title: "Improved Accuracy",
              description: "Continuously improve your process by letting your system learn!",
              
            },
            {
              imageSrc: <OpenInFullIcon/>,
              title: "Endless Continuous Improvement",
              description: "It only gets better; your BPM system evolves with you every step of the way!",
              
            },
            {
              imageSrc: <AttachMoneyIcon/>,
              title: "Reduced Costs",
              description: "Reduction of human workloads and better workflows result in significant savings",
              
            },
            
            {
              imageSrc: <RocketLaunchIcon/>,
              title: "Reduced time to market",
              description: "Low-code pace for faster go-to market",
              
            },
            {
              imageSrc: <GroupWorkIcon/>,
              title: "Cultivate Collaborations",
              description: "Structures and maps processes to one another to eliminate silos",
              
            },
            {
              imageSrc: <InsightsIcon/>,
              title: "Monitor Results",
              description: "Monitor end-to-end processes to ensure continuous improvement.",
              
            },
          ],
        render: true
    },
    Section5:{
        heading : "THE APPBAY FACTOR",
        subheading : "",
        description : "At Appbay, we help our customers build enduring non-stop environments that enable the business to work uninterrupted. Our end-to-end Business Process Management (BPM) Services span across all the lifecycle phases of the project, Consulting, Documentation and UX Design, Development and Automation, Quality Testing and Support.",
        imgSrc: logo,
        render: true
    },

}
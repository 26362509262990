import pdf from '../../pdf/Appbay - Case Study For Healthcare Domain.pdf'
import HealthCare1 from 'images/Healthcare.svg'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const HealthCare = {

    heading: "HealthCare",
    subheading: "",
    description: <>
    <strong>Client : </strong>Health care Customer<br/><br/>
    <strong>Challange : </strong><br/>
    <p><ChevronRightIcon/> Client had multiple practices and at multiple location and hence needed support to manage the needful at all levels: strategic, tactical and operational. </p>
    <p><ChevronRightIcon/> Silos of data in multiple excels. </p>
    <p><ChevronRightIcon/> No centre point of view for all the legacy systems. </p>
    <p><ChevronRightIcon/> Too much manual intervention in every business process making it error prone. </p>
    <br/>
    <strong>Solution : </strong>Multi-tenant application (SaaS product) built on Appian that allows multiple customers/clients to get onboarded and use the EPD product application that not only manages medical data for patients, but allows you to plan appointments fully automatically, billing, the daily agenda for practitioners and reports to show how well the practice is doing, also has capabilities to configure and manage the treatment templates for different types of treatments dynamically.
    <br/><br/>
    <strong>ROI : </strong>
    <p><ChevronRightIcon/> Improving overall "Turn Around Time" of each process </p>
    <p><ChevronRightIcon/> Increased revenue for the client by 20% in the first year of product implementation </p>
    </>,
    imgSrc: HealthCare1,
    pdf: pdf

}
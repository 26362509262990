import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration";
import {
  useParams
} from "react-router-dom";
import { Telecom } from "content/Case Study/Telecom";
import { Translation } from "content/Case Study/Translation";
import { Insurance } from "content/Case Study/Insurance";
import { HealthCare } from "content/Case Study/HealthCare";
import { Banking } from "content/Case Study/Banking";
import { Governance } from "content/Case Study/Governance";

export default (params) => {
  let { contentRef } = useParams();
  const referContent = {
    "Telecom": Telecom,
    "Translation": Translation,
    "Insurance": Insurance,
    "HealthCare": HealthCare,
    "Banking": Banking,
    "Governance":Governance
  }
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm 
        subheading = {referContent[contentRef].subheading}
        heading = {referContent[contentRef].heading}
        description = {referContent[contentRef].description}
        pdf={referContent[contentRef].pdf}
        imgSrc={referContent[contentRef].imgSrc}
      />
      
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { useParams } from "react-router-dom";
import LocationCards from "components/cards/LocationCards";

const Address = tw.span`leading-relaxed max-w-6xl`;
const AddressLine = tw.span`block`;
const Email = tw.a`text-sm mt-6 block text-gray-900 hover:text-primary-500`;
const Phone = tw.a`text-sm mt-3 block text-gray-900 hover:text-primary-500`;

export default () => {
  const { type } = useParams();
  console.log(type);

  const index = type === "connect" ? 0 : 1;

  const textContent = {
    heading: ["Let's connect", "Connect to an expert"],
    subheading: [
      "Share your great ideas with us. Let's make it happen for you!",
      "",
    ],
    description: [""],
    message: ["Message", "Consultation required for"],
    link: [
      "https://form.jotform.com/220063204941444",
      "https://form.jotform.com/220063033743444",
    ],
  };

  return (
    <AnimationRevealPage>
      <Header />
      {/* <ContactUsForm 
      subheading = {textContent.subheading[index]}
      heading = {textContent.heading[index]}
      description = {textContent.description[index]}
      link={textContent.link[index]}
      /> */}

      <ContactDetails
        cards={[
          {
            title: "Jaipur",
            description: (
              <>
                <Address>
                  <AddressLine>
                    4th floor, 228, Sindhi Colony Rd, Raja Park, Jaipur,
                    Rajasthan 302004
                    <div style={{height:"25px"}}/>
                  </AddressLine>
                </Address>
              </>
            ),
            imgURL:
              "https://images.pexels.com/photos/894443/pexels-photo-894443.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            email: "mailto:parthgupta@appbaytech.com",
            phone: "+91-9742232952",
          },

          {
            title: "Gurgaon",
            description: (
              <>
                <Address>
                  <AddressLine> Plot 84P, Second floor,</AddressLine>
                  <AddressLine>Huda-Plots, Devinder Vihar Road,</AddressLine>

                  <AddressLine>Sector 56, Gurgaon, Haryana, 122011</AddressLine>
                </Address>
              </>
            ),
            imgURL:
              "https://images.pexels.com/photos/904272/pexels-photo-904272.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            email: "mailto:parthgupta@appbaytech.com",
            phone: "tel:+91-9742232952",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from '@mui/material/Box';
import { SectionHeading, Subheading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import tw from "twin.macro";
import { Container } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Picture2 from 'images/Companies/Picture2.png'
import Picture3 from 'images/Companies/Picture3.png'
import Picture4 from 'images/Companies/Picture4.png'
import Picture5 from 'images/Companies/Picture5.png'
import Picture6 from 'images/Companies/Picture6.png'
import Picture7 from 'images/Companies/Picture7.png'
import Picture8 from 'images/Companies/Picture8.png'
import Picture9 from 'images/Companies/Picture9.png'
import Picture10 from 'images/Companies/Picture10.png'
import Picture11 from 'images/Companies/Picture11.png'
import Picture12 from 'images/Companies/Picture12.png'
import Picture13 from 'images/Companies/Picture13.png'
import Picture14 from 'images/Companies/Picture14.png'
import Picture15 from 'images/Companies/Picture15.png'
import Picture16 from 'images/Companies/Picture16.png'
import Picture17 from 'images/Companies/Picture17.png'
import { border } from '@mui/system';


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const ActionButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full px-3`;

function BasicCard(props) {
    return (

        <Container>
            <img alt="Remy Sharp" src={props.imageSrc} lazy
                style={{ width: 70, height: 70, marginLeft: 'auto', marginRight: 'auto', backgroundSize: 'contain', objectFit: 'scale-down',
            
            }}
                tw="my-5"
            />
        </Container>
    );
}
const Heading = tw(SectionHeading)`w-full lg:mt-8 text-2xl`;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 9
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 5
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};
;

const techStacks = [
    {
        imageSrc: Picture2,
    },
    {
        imageSrc: Picture3,
    },
    {
        imageSrc: Picture4,
    },
    {
        imageSrc: Picture5,
    },
    {
        imageSrc: Picture6,
    },
    {
        imageSrc: Picture7,
    },
    {
        imageSrc: Picture8,
    },
    {
        imageSrc: Picture9,
    },
    {
        imageSrc: Picture10,
    },
    {
        imageSrc: Picture11,
    },
    {
        imageSrc: Picture12,
    },
    {
        imageSrc: Picture13,
    },
    {
        imageSrc: Picture14,
    },
    {
        imageSrc: Picture15,
    },
    {
        imageSrc: Picture16,
    },
    {
        imageSrc: Picture17,
    },
]



export default function CompanySlider(props) {
    return (
        <Container>
            <Heading style={{top:10}} tw="mb-5">Companies looking <span tw="text-primary-500 font-black">for You</span></Heading>
            <Paper elevation={0} tw="mb-10">
                <Carousel responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {
                        techStacks.map((stack, i) => {
                            return <BasicCard
                                name={stack.name}
                                imageSrc={stack.imageSrc}
                                duration={stack.duration}
                                description={stack.description}
                                props={props}
                            />

                        })
                    }
                </Carousel>

            </Paper>
        </Container>
    );
}

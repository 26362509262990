import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative my-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto  items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/3 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-1/2 mt-5 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center`;
const Heading = tw(SectionHeading)`w-full lg:mt-8 py-4`;
const Description = tw.p`mt-4 text-justify lg:px-0 px-3  text-sm  lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`my-10 px-10 text-sm inline-block mx-auto`;

export default ({
  subheading = "",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://training.appbaytech.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  showButton = false,
  RedirectUrl=null,
  imageAlt,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  return (
    <Container>
      <Subheading>{subheading}</Subheading>
      <Heading>{heading}</Heading>
      <TwoColumn>
        <ImageColumn>
        <a href={RedirectUrl} target="_blank">
          <Image
            alt={imageAlt}
            css={imageCss}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
        </a>
          
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Description>{description}</Description>
            {showButton ? (
              <PrimaryButton
                buttonRounded={buttonRounded}
                as="a"
                href={primaryButtonUrl}
              >
                {primaryButtonText}
              </PrimaryButton>
            ) : null}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithSteps";
import MainFeature3 from "components/features/SixColSimple";
import ContactUs from "components/forms/TwoColContactUsWithIllustrationFullForm";

// import Features from "components/features/ThreeColWithSideImage.js";

import { useParams } from "react-router-dom";
import { bpm } from "content/Service by Business Needs/BPM";
import { crm } from "content/Service by Business Needs/CRM";
import { uiux } from "content/Service by Business Needs/UIUX";
import { training } from "content/Service by Business Needs/Training";
import { consultation } from "content/Service By Capablities/Consultation";
import { development } from "content/Service By Capablities/Development";
import { quality } from "content/Service By Capablities/Quality";
import { excellence } from "content/Service By Capablities/Excellence";
import SimpleSlider from "components/carasoual/slider";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Services = (params) => {
  let { contentRef } = useParams();
  const referContent = {
    bpm: bpm,
    crm: crm,
    training: training,
    Consultation: consultation,
    Development: development,
    Quality: quality,
    Excellence: excellence,
  };
  useEffect(() => {
    return () => {};
  }, []);

  String.prototype.toCamelCase = function () {
    let STR = this.toLowerCase()
      .trim()
      .split(/[ _]/g)
      .map((word) => word.replace(word[0], word[0].toString().toUpperCase()))
      .join(" ");
    return STR;
  };

  const Content = {
    Section1: {
      heading: referContent[contentRef].Section1.heading,
      subheading: referContent[contentRef].Section1.subheading,
      description: referContent[contentRef].Section1.description,
      imgSrc: referContent[contentRef].Section1.imgSrc,
      imageAlt: referContent[contentRef].Section1.heading,
      render: referContent[contentRef].Section1.render,
      showButton: referContent[contentRef].Section1.showButton,
      primaryButtonText: referContent[contentRef].Section1.primaryButtonText,
      primaryButtonUrl: referContent[contentRef].Section1.primaryButtonUrl,
    },
    Section2: {
      heading: referContent[contentRef].Section2.heading,
      subheading: referContent[contentRef].Section2.subheading,
      description: referContent[contentRef].Section2.description,
      imgSrc: referContent[contentRef].Section2.imgSrc,
      imageAlt: referContent[contentRef].Section2.heading,
      render: referContent[contentRef].Section2.render,
      extraContent: referContent[contentRef].Section2.extraContent,
    },
    Section3: {
      heading: referContent[contentRef].Section3.heading,
      subheading: referContent[contentRef].Section3.subheading,
      description: referContent[contentRef].Section3.description,
      imgSrc: referContent[contentRef].Section3.imgSrc,
      imageAlt: referContent[contentRef].Section3.heading,
      render: referContent[contentRef].Section3.render,
      tabs: referContent[contentRef].Section3.tabs,
      image: referContent[contentRef].Section3.image,
    },
    Section4: {
      heading: referContent[contentRef].Section4.heading,
      cards: referContent[contentRef].Section4.cards,
      render: referContent[contentRef].Section4.render,
    },
    Section5: {
      heading: referContent[contentRef].Section5.heading,
      subheading: referContent[contentRef].Section5.subheading,
      description: referContent[contentRef].Section5.description,
      imgSrc: referContent[contentRef].Section5.imgSrc,
      render: referContent[contentRef].Section5.render,
      imageAlt: referContent[contentRef].Section5.heading,
    },
  };

  return (
    <AnimationRevealPage>
      <Header />
      {Content.Section1.render ? (
        <MainFeature1
          subheading={<Subheading>{Content.Section1.subheading}</Subheading>}
          heading={Content.Section1.heading}
          description={Content.Section1.description}
          imageSrc={Content.Section1.imgSrc}
          showButton={Content.Section1.showButton}
          primaryButtonText={Content.Section1.primaryButtonText}
          primaryButtonUrl={Content.Section1.primaryButtonUrl}
          imageAlt={Content.Section1.imageAlt}
        />
      ) : null}
      {Content.Section2.render ? (
        <MainFeature2
          subheading={<Subheading>{Content.Section2.subheading}</Subheading>}
          heading={Content.Section2.heading}
          description={Content.Section2.description}
          imageSrc={Content.Section2.imgSrc}
          extraContent={Content.Section2.extraContent}
          textOnLeft={false}
          imageAlt={Content.Section2.imageAlt}
        />
      ) : null}
      {Content.Section3.render ? (
        <MainFeature2
          subheading={<Subheading>{Content.Section3.subheading}</Subheading>}
          heading={Content.Section3.heading}
          description={Content.Section3.description}
          imageSrc={Content.Section3.imgSrc}
          textOnLeft={true}
          tabs={Content.Section3.tabs}
          image={Content.Section3.image}
          imageAlt={Content.Section3.imageAlt}
        />
      ) : null}
      {Content.Section4.render ? (
        <MainFeature3
          heading={Content.Section4.heading}
          cards={Content.Section4.cards}
        />
      ) : null}
      {Content.Section5.render ? (
        <MainFeature2
          subheading={<Subheading>{Content.Section5.subheading}</Subheading>}
          heading={Content.Section5.heading}
          description={Content.Section5.description}
          imageSrc={Content.Section5.imgSrc}
          textOnLeft={false}
          halfImage={true}
          imageAlt={Content.Section5.imageAlt}
        />
      ) : null}
      {contentRef === "training" ? (
        <>
          <SimpleSlider />
          <ContactUs
            subheading=""
            heading="Connect to a learning advisor"
            description=""
            message="Your preferred technology..."
            link="https://form.jotform.com/220063033743444"
          />
        </>
      ) : null}
      <Footer />
    </AnimationRevealPage>
  );
};

export default Services;

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import CaseStudyNavCards from "../cards/ProfileThreeColGrid";
import JotFormReact from "jotform-react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { PrimaryLink } from "components/headers/light";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-5 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left `;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-justify px-3 md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mb-10`;

const Form = tw.div`mt-10 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-6 lg:mt-6 px-3`;

function Hidden() {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: 50,
        width: window.innerWidth,
        bottom: 50,
        position: "relative",
      }}
    ></div>
  );
}

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "",
  submitButtonText = "Download",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  pdf = "",
  imgSrc = "",
  props,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  let history = useHistory();
  const handleSubmit = () => {
    const pdfWindow = window.open();
    pdfWindow.location.href = pdf;
  };

  return (
    <Container>
      <CaseStudyNavCards />
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={imgSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>

            {/* <Form>
              <Input type="text" name="name" placeholder="Your Name" />
              <Input type="text" name="designation" placeholder="Your Designation" />
              <Input type="email" name="email" placeholder="Your Email Address" />
            <a href={pdf} target='_blank' rel='noopener noreferrer'><SubmitButton>{submitButtonText}</SubmitButton></a>
            </Form> */}
            {/* <JotFormReact
              formURL="https://form.jotform.com/220265124662449"
              onSubmit={handleSubmit}
              initialHeight={300} // Form will opens in this size
              autoResize={true} // should form auto-resize
              tw="mt-10"
            /> */}
          </TextContent>
          <Subheading>
            Discover the full case study!{" "}
            <a
              style={{ color: "black", textDecoration: "underline" }}
              href="/contact-us/connect"
            >
              Contact us
            </a>{" "}
            now for exclusive access via email. Your success story awaits!{" "}
          </Subheading>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
